import gql from 'graphql-tag';

const GET_USER_PERMISSIONS_QUERY = gql`
  query ADash_UserPermmissionsQuery {
    viewer {
      id
      user {
        id
        permissions {
          id
          oneCommerce {
            admin {
              access
            }
          }
        }
      }
    }
  }
`;

export default GET_USER_PERMISSIONS_QUERY;
