import React from 'react';
import AddressesTable from './AddressesTable/AddressesTable';

interface AddressesProps {
  addresses: any;
  selectedUserId: string;
  refetchFunction: () => void;
}

const Addresses: React.FunctionComponent<AddressesProps> = ({
  addresses,
  selectedUserId,
  refetchFunction,
}) => (
  <AddressesTable
    addresses={addresses}
    selectedUserId={selectedUserId}
    refetchFunction={refetchFunction}
  />
);
export default Addresses;
