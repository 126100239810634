import React from 'react';
import {
  ADash_SubscriptionsQuery_viewer_userInfo_autoShips,
  ADash_SubscriptionsQuery_viewer_userInfo_subscriptions,
} from '../../queries/generatedTypes/ADash_SubscriptionsQuery';
import SubscriptionCards from './SubscriptionCards/SubscriptionCards';

interface SubscriptionsProps {
  autoShips: ADash_SubscriptionsQuery_viewer_userInfo_autoShips[];
  refetchSubscriptions: () => void;
  selectedUserId: string;
  subscriptions: ADash_SubscriptionsQuery_viewer_userInfo_subscriptions;
}

const Subscriptions = ({
  autoShips,
  refetchSubscriptions,
  selectedUserId,
  subscriptions,
}: SubscriptionsProps) => {
  return (
    <SubscriptionCards
      subscriptions={subscriptions}
      autoShips={autoShips}
      selectedUserId={selectedUserId}
      refetchSubscriptions={refetchSubscriptions}
    />
  );
};

export default Subscriptions;
