import gql from 'graphql-tag';

const AUTOSHIP_CANCEL_MUTATION = gql`
  mutation AutoShipCancelMutationMutation(
    $input: AutoShipCancelMutationInput!
  ) {
    autoShipCancel(input: $input) {
      deletionStatus
    }
  }
`;

export default AUTOSHIP_CANCEL_MUTATION;
