import gql from 'graphql-tag';

const mutation = gql`
  mutation AdminDash_StripePaymentAddMutation($input: StripePaymentAddInput!) {
    stripePaymentAdd(input: $input) {
      entryEdge {
        cursor
        node {
          id
          stripePayment {
            id
            name
            last4
            expYear
            expMonth
            billingAddressUuid
            paymentId
            object
            brand
            dynamicLast4
            funding
            tokenizationMethod
            billingAddress {
              id
              firstName
              lastName
              street
              addressLineOne
              addressLineTwo
              city
              state
              zip
              recipientBirthDate
              addressId
              country
              phoneNumber
              emailAddress
              specialInstructions
              nickname
              companyName
            }
            nickname
          }
        }
      }
      entryEdge {
        cursor
        node {
          id
          stripePayment {
            name
            last4
            expYear
            expMonth
            billingAddressUuid
            paymentId
            object
            brand
            dynamicLast4
            funding
            tokenizationMethod
            billingAddress {
              id
              firstName
              lastName
              street
              addressLineOne
              addressLineTwo
              city
              state
              zip
              recipientBirthDate
              addressId
              country
              phoneNumber
              emailAddress
              specialInstructions
              nickname
              companyName
            }
            nickname
          }
        }
      }
      stripePaymentCollectionEntry {
        id
        stripePayment {
          id
          name
          last4
          expYear
          expMonth
          billingAddressUuid
          paymentId
          object
          brand
          dynamicLast4
          funding
          tokenizationMethod
          billingAddress {
            id
            firstName
            lastName
            street
            addressLineOne
            addressLineTwo
            city
            state
            zip
            recipientBirthDate
            addressId
            country
            phoneNumber
            emailAddress
            specialInstructions
            nickname
            companyName
          }
          nickname
        }
      }
      stripePaymentCollection {
        id
        totalNumberOfStripePayment
        totalNumberOfStripePayments
        entries(first: 1000) {
          edges {
            cursor
            node {
              id
              stripePayment {
                id
                name
                last4
                expYear
                expMonth
                billingAddressUuid
                paymentId
                object
                brand
                dynamicLast4
                funding
                tokenizationMethod
                billingAddress {
                  id
                  firstName
                  lastName
                  street
                  addressLineOne
                  addressLineTwo
                  city
                  state
                  zip
                  recipientBirthDate
                  addressId
                  country
                  phoneNumber
                  emailAddress
                  specialInstructions
                  nickname
                  companyName
                }
                nickname
              }
            }
          }
        }
      }
      stripePayment {
        id
        name
        last4
        expYear
        expMonth
        billingAddressUuid
        paymentId
        object
        brand
        dynamicLast4
        funding
        tokenizationMethod
        billingAddress {
          id
          firstName
          lastName
          street
          addressLineOne
          addressLineTwo
          city
          state
          zip
          recipientBirthDate
          addressId
          country
          phoneNumber
          emailAddress
          specialInstructions
          nickname
          companyName
        }
        nickname
      }
    }
  }
`;

export default mutation;
