// import { Link } from "gatsby"
import React from 'react';
import Layout from 'antd/lib/layout';
import Icon from 'antd/lib/icon';
import { Query } from 'react-apollo';
import { debounce } from 'throttle-debounce';

import HeaderAutoComplete from './../../HeaderAutoComplete/HeaderAutoComplete';
import GET_USERS_QUERY from '../../../queries/UsersSearchQuery';

import './header.css';
const { Header } = Layout;

interface NavHeaderProps {
  collapsed: boolean;
  toggle: () => void;
}

const NavHeader: React.FunctionComponent<NavHeaderProps> = ({
  collapsed,
  toggle,
}) => (
  <Header style={{ background: '#fff', padding: 0, position: 'relative' }}>
    <Icon
      className="trigger"
      type={collapsed ? 'menu-unfold' : 'menu-fold'}
      onClick={toggle}
    />
    <Query
      query={GET_USERS_QUERY}
      variables={{
        searchInput: 'a',
        email: true,
        firstName: false,
        lastName: false,
        trinityDealerId: false,
        accountId: false,
        orderId: false,
      }}
    >
      {({ client, loading, error, refetch, data }) => {
        if (error) return `Error! ${error.message}`;

        const refetchFunction = debounce(
          300,
          (
            searchInput: string,
            email: boolean,
            firstName: boolean,
            lastName: boolean,
            trinityDealerId: boolean,
            accountId: boolean,
            orderId: boolean,
          ) => {
            refetch({
              email,
              firstName,
              lastName,
              trinityDealerId,
              accountId,
              orderId,
              searchInput,
            });
          },
        );

        const resetStore = async (cb: () => void) => {
          return cb();
          // } else {
          //   return await client.clearStore().then(() => {
          //     return cb();
          //   });
          // }
        };

        let dataSource = [null];
        if (!loading && data.viewer.users.edges.length > 0) {
          dataSource = data.viewer.users.edges;
        }

        return (
          <HeaderAutoComplete
            data={dataSource}
            loading={loading}
            resetStore={resetStore}
            refetchUsers={refetchFunction}
          />
        );
      }}
    </Query>
  </Header>
);

export default NavHeader;
