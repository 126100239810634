import gql from 'graphql-tag';

const mutation = gql`
  mutation GiftCartAddMutation($input: GiftCardAddInput!) {
    giftCardAdd(input: $input) {
      entryEdges {
        cursor
        node {
          id
          promoCodeId
          code
          startsOn
          credit
          usableByAccountId
          maxUses
          storeBalance
          categories
          isVoided
          blockCommissions
          promoDescription
          discountShipping
          timesUsed
          discountTax
          productIds
          expiresOn
          credit
          excludeProducts {
            productId
            excluded
            discountShipping
          }
          thresholds {
            thresholdId
            discountFree
            discountTax
            discountShipping
            discountAmountEach
            spendQuantity
            discountFree
            spendAmount
          }
        }
      }
    }
  }
`;

export default mutation;
