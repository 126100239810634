import React from 'react';
import InputValidations from './InputValidations';
import { Input, InputNumber, Select, DatePicker, Form } from 'antd';
import { EditableContext } from './PromoCodesTable';

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  index: number;
  record: any;
  key: string;
}
const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

class EditableCell extends React.Component<EditableCellProps, {}> {
  getInput = (initialInput: any) => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    } else if (this.props.inputType === 'textArea') {
      return <TextArea rows={4} />;
    } else if (this.props.inputType === 'date') {
      return <DatePicker />;
    } else if (this.props.inputType === 'blockCommissions') {
      return (
        <Select style={{ width: 200 }}>
          <Option key="1" value="true">
            BLOCK COMMISSIONS
          </Option>
          <Option key="2" value="false">
            ALLOW COMMISSIONS
          </Option>
        </Select>
      );
    } else if (this.props.inputType === 'isVoided') {
      return (
        <Select style={{ width: 150 }}>
          <Option key="1" value="true">
            ACTIVE
          </Option>
          <Option key="2" value="false">
            INACTIVE
          </Option>
        </Select>
      );
    } else if (this.props.inputType === 'isSingleUse') {
      return (
        <Select style={{ width: 150 }}>
          <Option key="1" value="true">
            YES
          </Option>
          <Option key="2" value="false">
            NO
          </Option>
        </Select>
      );
    } else if (this.props.inputType === 'categories') {
      return (
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Select Categories"
          defaultActiveFirstOption={false}
        >
          <Option key="1" value="categoryWine">
            Wine
          </Option>
          <Option key="2" value="categoryGifts">
            Gifts
          </Option>
          <Option key="3" value="categoryVitner">
            Vitner
          </Option>
        </Select>
      );
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      index,
      ...restProps
    } = this.props;
    const record = this.props.record ? this.props.record.node : undefined;
    const dataKey = dataIndex ? dataIndex.split('.')[1] : null;
    const rules = InputValidations[dataIndex]
      ? InputValidations[dataIndex].rules
      : undefined;
    return (
      <EditableContext.Consumer>
        {(form: any) => {
          const { getFieldDecorator } = form;
          let initialValue = null;
          if (dataKey === 'categories') {
            initialValue = record[dataKey] || [];
          } else if (
            dataKey === 'blockCommissions' ||
            dataKey === 'isVoided' ||
            dataKey === 'isSingleUse'
          ) {
            if (record[dataKey]) {
              initialValue = 'true';
            } else if (record[dataKey] === false) {
              initialValue = 'false';
            }
          } else if (record) {
            initialValue = record[dataKey];
          }
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules,
                    initialValue,
                  })(this.getInput(initialValue))}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

export default EditableCell;
