import gql from 'graphql-tag';

const mutation = gql`
  mutation AdminDash_OrderEditMutation($input: OrderEditInput!) {
    orderEdit(input: $input) {
      orderId
      order {
        id
        accountFullName
        createDate
        orderStatus
        orderTotal
        orderTax
        orderSubTotal
        email
        giftMessage {
          message
          recipientName
          from
        }
        giftReceipt
        isProcessing
        giftCards
        giftCardSavings
        promoCodes
        promoCodeSavings
        trinityTransmissionStatus
        trinityOrderId
        trinityPartyId
        orderItems {
          id
          quantity
          priceBought
          product {
            id
            productId
            name
            price
            priceSale
            productURLslug
            staticUrl
            images {
              productCatalog
            }
            content {
              impactSmall
            }
            inventoryQuantity
            company
            inventorySKU
            inventoryClass
            inventoryText
            isSubscription
            promoCodeDiscount {
              case4Cause {
                case12PriceUSD
                case6PriceUSD
              }
            }
          }
        }
        shippingChosen
        shippingInfo {
          carrier
          tracking
          expectedDeliveryDate
        }
        shippingAddress {
          firstName
          lastName
          specialInstructions
          street
          addressLineOne
          addressLineTwo
          city
          state
          zip
          phone
        }
        paymentInfo {
          name
          last4
          expYear
          expMonth
          billingAddressUuid
          provider
        }
        orderShipping
        billingAddressIsShippingAddress
        billingAddress {
          firstName
          lastName
          street
          addressLineOne
          addressLineTwo
          city
          state
          zip
        }
        recipientBirthDate
      }
    }
  }
`;

export default mutation;
