import React from 'react';
import { Spin } from 'antd';
import { Query } from 'react-apollo';
import _get from 'lodash/get';

import Layout from '../components/MainLayout/layout/layout';
import Tabs from '../components/Tabs/Tabs';
import Payments from '../components/Payments';

import { getCurrentUserId } from '../utils/utils';
import GET_PAYMENTS_QUERY from '../queries/Payments';

interface PaymentsPageProps {
  location: any;
}

interface PaymentsPageState {
  collapsed: boolean;
}

class PaymentsPage extends React.Component<
  PaymentsPageProps,
  PaymentsPageState
> {
  state = {
    collapsed: false,
  };

  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout pathname={location.pathname} selectedUserId={currentUser}>
        <Query
          query={GET_PAYMENTS_QUERY}
          variables={{
            accountId: currentUser,
            csPortal: true,
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) return <Spin size="large" />;
            if (error) return `Error! ${error.message}`;
            const {
              viewer: {
                userInfo,
                userInfo: { firstName, lastName },
              },
            } = data;

            const refetchFunction = () => {
              return refetch({
                accountId: currentUser,
                csPortal: true,
              });
            };
            const isCeoSelected = _get(
              data.viewer.userInfo,
              'permissions.oneCommerce.ceo.access',
            );
            const userText = isCeoSelected ? 'Ceo' : 'Customer';
            return (
              <React.Fragment>
                <h3>
                  Viewing: {`${firstName} ${lastName}`} ({userText})
                </h3>
                <Tabs
                  isCeoSelected={isCeoSelected}
                  selectedValue="payments"
                  selectedUserId={currentUser}
                />
                <Payments
                  refetchFunction={refetchFunction}
                  selectedUserId={currentUser}
                  payments={userInfo.stripePaymentCollection.entries.edges}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default PaymentsPage;
