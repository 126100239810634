import gql from 'graphql-tag';

const mutation = gql`
  mutation PromoCodeEditMutation($input: PromoCodeEditInput!) {
    promoCodeEdit(input: $input) {
      promoCodeId
      entryEdge {
        cursor
        node {
          id
          promoCodeId
          code
          startsOn
          credit
          usableByAccountId
          maxUses
          storeBalance
          categories
          isVoided
          isSingleUse
          usableByEmails
          blockCommissions
          promoDescription
          discountShipping
          timesUsed
          discountTax
          productIds
          expiresOn
          credit
          excludeProducts {
            productId
            excluded
            discountShipping
          }
          thresholds {
            thresholdId
            discountFree
            discountTax
            discountShipping
            discountAmountEach
            spendQuantity
            discountFree
            spendAmount
          }
        }
      }
      promoCode {
        id
        id
        promoCodeId
        code
        startsOn
        credit
        usableByEmails
        usableByAccountId
        maxUses
        storeBalance
        categories
        isVoided
        isSingleUse
        blockCommissions
        promoDescription
        discountShipping
        timesUsed
        discountTax
        productIds
        expiresOn
        credit
        excludeProducts {
          productId
          excluded
          discountShipping
        }
        thresholds {
          thresholdId
          discountFree
          discountTax
          discountShipping
          discountAmountEach
          spendQuantity
          discountFree
          spendAmount
        }
      }
    }
  }
`;

export default mutation;
