import { statesAbbrev } from '../../../utils/utils';

const validations = {
  'node.stripePayment.billingAddress.addressLineOne': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.stripePayment.billingAddress.firstName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.stripePayment.billingAddress.lastName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.stripePayment.billingAddress.city': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.stripePayment.billingAddress.state': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        type: 'enum',
        enum: statesAbbrev,
        message: `Invalid State`,
      },
    ],
  },
  'node.stripePayment.billingAddress.zip': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        len: 5,
        message: `Invalid Zip`,
      },
    ],
  },
  'node.stripePayment.expMonth': {
    rules: [
      {
        transform: val => val.toString(),
      },
      {
        max: 2,
        message: `Max 2 chars`,
      },
      {
        type: 'enum',
        enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        message: `Invalid Month`,
      },
    ],
  },
  'node.stripePayment.expYear': {
    rules: [
      {
        transform: val => val.toString(),
      },
      {
        max: 4,
        message: `Max 4 chars`,
      },
      {
        type: 'enum',
        enum: [
          '2019',
          '2020',
          '2021',
          '2022',
          '2023',
          '2024',
          '2025',
          '2026',
          '2027',
          '2028',
          '2029',
          '2030',
          '2031',
          '2032',
          '2033',
          '2034',
          '2035',
        ],
        message: `Invalid Year`,
      },
    ],
  },
};

export default validations;
