import gql from 'graphql-tag';

const GET_CUSTOMERS_QUERY = gql`
  query ADash_CustomersQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        customers(first: 10000, sortBy: "any")
          @connection(key: "CeoCustomersPage_customers") {
          edges {
            node {
              id
              accountId
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

export default GET_CUSTOMERS_QUERY;
