import React from 'react';
import { Spin } from 'antd';
import { Query } from 'react-apollo';
import _get from 'lodash/get';

import Layout from '../components/MainLayout/layout/layout';
import Tabs from '../components/Tabs/Tabs';
import Subscriptions from '../components/Subscriptions';

import { getCurrentUserId } from '../utils/utils';
import GET_SUBSCRIPTIONS_QUERY from '../queries/Subscriptions';
import {
  ADash_SubscriptionsQuery,
  ADash_SubscriptionsQueryVariables,
} from '../queries/generatedTypes/ADash_SubscriptionsQuery';

interface SubscriptionsPageProps {
  location: any;
}

class SubscriptionsPage extends React.Component<SubscriptionsPageProps, {}> {
  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout pathname={location.pathname} selectedUserId={currentUser}>
        <Query<ADash_SubscriptionsQuery, ADash_SubscriptionsQueryVariables>
          query={GET_SUBSCRIPTIONS_QUERY}
          variables={{
            accountId: currentUser,
            csPortal: true,
            includeCanceled: true,
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) return <Spin size="large" />;
            if (error) return <>Error! ${error.message}</>;
            const {
              viewer: {
                userInfo: { firstName, lastName, subscriptions, autoShips },
              },
            } = data;
            const isCeoSelected = _get(
              data.viewer.userInfo,
              'permissions.oneCommerce.ceo.access',
            );
            const userText = isCeoSelected ? 'Ceo' : 'Customer';

            const refetchSubscriptions = () => {
              refetch();
            };

            return (
              <React.Fragment>
                <h3>
                  Viewing: {`${firstName} ${lastName}`} ({userText})
                </h3>
                <Tabs
                  isCeoSelected={isCeoSelected}
                  selectedValue="subscriptions"
                  selectedUserId={currentUser}
                />
                <Subscriptions
                  selectedUserId={currentUser}
                  subscriptions={subscriptions}
                  autoShips={autoShips}
                  refetchSubscriptions={refetchSubscriptions}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default SubscriptionsPage;
