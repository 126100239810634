import React from 'react';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Divider from 'antd/lib/divider';
import Layout from '../components/MainLayout/layout/layout';
import { Button, Input, styles } from '@onehope/design-system';

const {
  styled: s,
  antDesignStyles: {
    typography: { Title },
  },
} = styles;

const emailValidationConfig = () => {
  return {
    validateTrigger: 'onBlur',
    rules: [
      {
        type: 'email',
        message: '🔤 The input is not a valid email',
      },
      { required: true, message: 'Please input your email' },
    ],
  };
};

const EmailPageComponent = ({ form }) => {
  const { getFieldDecorator } = form;

  return (
    <React.Fragment>
      <Form>
        <Form.Item hasFeedback>
          {getFieldDecorator(
            'email',
            // @ts-ignore: says that config does not match up with typings
            emailValidationConfig(),
          )(<Input label="Email address" kind="text" />)}
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

const SampleForm = Form.create({
  name: 'sample_form',
})(EmailPageComponent);

const DesignSystemPage = () => (
  <Layout selectedUserId={''} pathname="/design">
    <React.Fragment>
      <Row gutter={32} type="flex">
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Title level={4}>REGULAR</Title>
          <Button block type="primary-regular">
            Primary
          </Button>
          <Button disabled block type="primary-regular">
            Primary Disabled
          </Button>
          <Button block type="secondary-regular">
            Secondary
          </Button>
          <Button disabled block type="secondary-regular">
            Secondary Disabled
          </Button>
          <Button block type="tertiary-regular">
            Tertiary
          </Button>
          <Button disabled block type="tertiary-regular">
            Tertiary Disabled
          </Button>
          <Button block icon="google" type="google">
            Google
          </Button>
          <Button block icon="facebook" type="facebook">
            Facebook
          </Button>
          <Row>
            <Button width={136} type="secondary-regular">
              Cancel
            </Button>
            <s.SpacerButtonHorizontal css />
            <Button width={136} type="primary-regular">
              Submit
            </Button>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
          <Title level={4}>LARGE</Title>
          <Button block type="primary-large">
            Next
          </Button>
          <Button disabled block type="primary-large">
            Next
          </Button>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Title level={4}>SMALL / CHIPS</Title>
          <Button block type="primary-regular">
            Next
          </Button>
          <Button disabled block type="primary-large">
            Next
          </Button>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6}>
          <Title level={4}>FABS</Title>
          <Button block type="primary-large">
            Next
          </Button>
          <Button disabled block type="primary-large">
            Next
          </Button>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col xs={8}>
          <Title level={4}>INPUTS</Title>
          <SampleForm />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Title level={4}>TYPOGRAPHY: Titles</Title>
          <Title level={1}>h1. ONEHOPE / Doing Good</Title>
          <Title level={2}>h2. ONEHOPE / Doing Good</Title>
          <Title level={3}>h3. ONEHOPE / Doing Good</Title>
          <Title level={4}>h4. ONEHOPE / Doing Good</Title>
        </Col>
      </Row>
    </React.Fragment>
  </Layout>
);

export default DesignSystemPage;
