import React from 'react';
import { Link } from 'gatsby';
import { Query, Mutation } from 'react-apollo';
import Layout from 'antd/lib/layout';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';
import Divider from 'antd/lib/divider';

import onehopeLogo from '../../../assets/ONEHOPE_Logo.png';

import UserLogoutMutation from '../../../mutations/Authentication/LogoutMutation';
import GET_SELECTED_USER_PERMISSIONS_QUERY from '../../../queries/SelectedUserPermissions';

import 'antd/lib/menu/style/index.css';
import 'antd/lib/divider/style/index.css';
import './sider.css';
import { redirectToLoginV2 } from '../../../utils/utils';
const { Sider } = Layout;

interface SiderNavProps {
  defaultSelectedKeys: Array<string>;
  collapsed: boolean;
  selectedUserId: string;
}

function logoutSuccess() {
  redirectToLoginV2(process.env.GATSBY_ADMIN_DASH_URL);
}

const SiderNav: React.FunctionComponent<SiderNavProps> = ({
  defaultSelectedKeys,
  collapsed,
  selectedUserId,
}) => {
  return (
    <Query
      query={GET_SELECTED_USER_PERMISSIONS_QUERY}
      variables={{
        accountId: selectedUserId,
        csPortal: true,
      }}
    >
      {({ loading, error, data }) => {
        if (error) return `Error! ${error.message}`;
        let isUserSelected = false;
        if (!loading) {
          isUserSelected = data.viewer.userInfo.userId;
        }
        return (
          <Sider
            style={{ height: '100vh' }}
            width={256}
            breakpoint="lg"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            {!collapsed && (
              <div
                style={{
                  height: '70px',
                  width: '256px',
                  backgroundColor: '#262626',
                }}
              >
                <img alt="" className="logo" src={onehopeLogo} />
              </div>
            )}
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={defaultSelectedKeys}
            >
              <Menu.Item key="1" disabled={!isUserSelected}>
                <Icon type="table" />
                <span>Account/Order Lookup</span>
              </Menu.Item>
              <Menu.Item key="divider" disabled>
                <Divider style={{ color: '#A6ADB4' }} />
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/promos`}>
                  <Icon type="dollar" />
                  <span>Promo Codes</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="3" disabled>
                <Link to={`/donations`}>
                  <Icon type="gift" />
                  <span>Donations</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="4" disabled>
                <Link to={`/products`}>
                  <Icon type="shopping" />
                  <span>Products</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="5">
                <Mutation
                  mutation={UserLogoutMutation}
                  onCompleted={() => logoutSuccess()}
                >
                  {(logout: any, { loading, client }) => (
                    <div
                      onClick={() =>
                        logout({ variables: { input: {} } }).then(() =>
                          client.resetStore(),
                        )
                      }
                    >
                      <Icon type="logout" />
                      <span>Logout</span>
                    </div>
                  )}
                </Mutation>
              </Menu.Item>
            </Menu>
          </Sider>
        );
      }}
    </Query>
  );
};

export default SiderNav;
