import React, { Component } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import { Mutation, MutationFn } from 'react-apollo';
import EditableCell from './EditableCell';

import PromoCodeEdit from '../../../mutations/PromoCodes/PromoCodeEdit';
// import PaymentDeleteMutation from '../../../mutations/Payments/PaymentDelete';

import AddGiftCardModal from './AddGiftCardModal';
const { Group, Search } = Input;
// const InputGroup = Input.Group;
const Option = Select.Option;

export const EditableContext = React.createContext({});

type PromoCodeEditVariables = {
  input: {
    promoCodeId: string;
    promoCodeData: any;
  };
};

interface RefectchType {
  searchByCode: string;
  searchById: string;
}

interface PromoCodesTableProps {
  form: any;
  promoCodes: any;
  onLoadMore: (input: number) => void;
  totalCount: number;
  refetchFunction: ({ variables }: { variables: RefectchType }) => void;
}

interface PromoCodesTableState {
  isUpdating: boolean;
  editingKey: string;
  cancellingKey: string;
  data: Array<any>;
  record: any;
  row: any;
  searchText: string;
  isCancelling: boolean;
}

class PromoCodesTable extends Component<
  PromoCodesTableProps,
  PromoCodesTableState
> {
  private columns: Array<any>;
  constructor(props: PromoCodesTableProps) {
    super(props);
    this.state = {
      data: props.promoCodes,
      editingKey: '',
      cancellingKey: '',
      isUpdating: false,
      record: null,
      row: null,
      searchText: '',
      isCancelling: false,
      column: 'code',
      page: 1,
    };
    this.columns = [
      {
        title: 'Code',
        dataIndex: 'node.code',
        editable: true,
        key: 'code',
        render: (text: string) => {
          return (
            <Highlighter
              autoEscape
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={`${text ? text.toString() : ''}`}
            />
          );
        },
      },
      {
        title: 'Description',
        dataIndex: 'node.promoDescription',
        editable: true,
        width: 250,
        key: 'promoDescription',
      },
      {
        title: 'Active Date',
        dataIndex: 'node.startsOn',
        editable: true,
        key: 'startsOn',
        render: (text: string) => {
          const date = text
            ? moment
                .utc(text)
                .local()
                .format('ll')
            : '';
          return (
            <Highlighter
              autoEscape
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={!!date ? `${date}` : ''}
            />
          );
        },
      },
      {
        title: 'Exp. Date',
        dataIndex: 'node.expiresOn',
        editable: true,
        key: 'expiresOn',
        render: (text: string) => {
          const date = text
            ? moment
                .utc(text)
                .local()
                .format('ll')
            : '';
          return (
            <Highlighter
              autoEscape
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={!!date ? `${date}` : ''}
            />
          );
        },
      },
      {
        title: 'Block Commissions?',
        dataIndex: 'node.blockCommissions',
        editable: true,
        key: 'blockCommissions',
        render: (text: boolean) => {
          let blockCommissionText = '';
          if (text === true) {
            blockCommissionText = 'BLOCK COMMISSIONS';
          } else if (text === false) {
            blockCommissionText = 'ALLOW COMMISSIONS';
          }
          return (
            <Highlighter
              autoEscape
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={blockCommissionText || ''}
            />
          );
        },
      },
      {
        title: 'Promo Code Id',
        dataIndex: 'node.promoCodeId',
        editable: false,
        key: 'promoCodeId',
        // sorter: (a: any, b: any) =>
        //   a.node.promoCodeId.localeCompare(b.node.promoCodeId),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={text || ''}
          />
        ),
      },
      {
        title: 'Voided?',
        dataIndex: 'node.isVoided',
        editable: true,
        key: 'isVoided',
        render: (text: boolean) => {
          let isVoidedText = '';
          if (text === true) {
            isVoidedText = 'ACTIVE';
          } else if (text === false) {
            isVoidedText = 'INACTIVE';
          }
          return (
            <Highlighter
              autoEscape
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={isVoidedText || ''}
            />
          );
        },
      },
      {
        title: 'Store Balance',
        dataIndex: 'node.storeBalance',
        editable: true,
        key: 'storeBalance',
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={!!text ? `$${text}` : ''}
          />
        ),
      },
      {
        title: 'Store Balance Activity',
        dataIndex: 'node.storeBalanceActivity',
        editable: false,
        width: 200,
        key: 'storeBalanceActivity',
        render: (storeBalanceActivity: Array<any> | null) => {
          if (storeBalanceActivity) {
            return storeBalanceActivity.map((activity) => {
              const date = moment
                .utc(new Date(activity.date))
                .local()
                .format('ll');
              return (
                <Highlighter
                  autoEscape
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  textToHighlight={
                    !!activity.credit ? `${date}: ${activity.credit}` : ''
                  }
                />
              );
            });
          }
          return '';
        },
      },
      {
        title: 'Categories',
        dataIndex: 'node.categories',
        editable: true,
        key: 'categories',
        width: 250,
        render: (categories: Array<string> | null) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={categories ? categories.join(', ') : ''}
          />
        ),
      },
      {
        title: 'Specify Products to Include',
        dataIndex: 'node.productIds',
        editable: true,
        key: 'productIds',
        width: 350,
        render: (productIds: Array<string> | null) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={(productIds || []).join(', ')}
          />
        ),
      },
      {
        title: 'Usable Emails',
        dataIndex: 'node.usableByEmails',
        editable: true,
        width: 200,
        key: 'usableByEmails',
        render: (usableByEmails: Array<string> | null) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={usableByEmails ? usableByEmails.join(', ') : ''}
          />
        ),
      },
      {
        title: 'Single Use?',
        dataIndex: 'node.isSingleUse',
        editable: true,
        key: 'isSingleUse',
        render: (text: boolean) => {
          let isSingleUseText = '';
          if (text === true) {
            isSingleUseText = 'YES';
          } else if (text === false) {
            isSingleUseText = 'NO';
          }
          return (
            <Highlighter
              autoEscape
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={isSingleUseText || ''}
            />
          );
        },
      },
      {
        title: 'Max Uses',
        dataIndex: 'node.maxUses',
        editable: true,
        key: 'maxUses',
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={text || ''}
          />
        ),
      },
      {
        title: 'Times Used',
        dataIndex: 'node.timesUsed',
        editable: true,
        key: 'timesUsed',
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={text || ''}
          />
        ),
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        width: '125px',
        fixed: 'right',
        render: (text: string, record: any) => {
          const editable = this.isEditing(record);
          const { editingKey } = this.state;
          return (
            <div>
              <Mutation
                mutation={PromoCodeEdit}
                onError={this.onMutationError}
                onCompleted={this.onPromoCodeUpdated}
              >
                {(editPromoCode, { data }) => (
                  <div>
                    {editable ? (
                      <span>
                        <EditableContext.Consumer>
                          {(form) => (
                            <Popconfirm
                              title="Sure to save?"
                              cancelText="Close"
                              okText="Yes"
                              onConfirm={() =>
                                this.submitPromoCodeUpdate(
                                  form,
                                  record,
                                  editPromoCode,
                                )
                              }
                            >
                              <Button
                                htmlType="button"
                                style={{ marginRight: 8 }}
                                type="primary"
                                shape="circle"
                                icon="save"
                                size="small"
                              />
                            </Popconfirm>
                          )}
                        </EditableContext.Consumer>
                        <React.Fragment>
                          <Button
                            onClick={this.cancel}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="close"
                            size="small"
                          />
                          {this.state.isUpdating && this.isEditing(record) && (
                            <Spin style={{ marginLeft: 8 }} size="small" />
                          )}
                        </React.Fragment>
                      </span>
                    ) : (
                      <span>
                        <Tooltip title="Edit">
                          <Button
                            onClick={() => this.edit(record)}
                            htmlType="button"
                            type="primary"
                            shape="circle"
                            icon="edit"
                            size="small"
                            disabled={editingKey !== ''}
                          />
                        </Tooltip>
                        {/*<Mutation*/}
                        {/*  mutation={PaymentDeleteMutation}*/}
                        {/*  onError={this.onMutationError}*/}
                        {/*  onCompleted={this.onPromoCodeUpdated}>*/}
                        {/*  {(deletePayment, { data }) =>*/}
                        {/*    <Popconfirm*/}
                        {/*      title="Delete Payment?"*/}
                        {/*      cancelText="Close"*/}
                        {/*      okText="Delete"*/}
                        {/*      onConfirm={() =>*/}
                        {/*        this.deletePayment(record, deletePayment)}>*/}
                        {/*      <span>*/}
                        {/*        <Button*/}
                        {/*          htmlType="button"*/}
                        {/*          disabled*/}
                        {/*          style={{ marginLeft: 8 }}*/}
                        {/*          type="danger"*/}
                        {/*          shape="circle"*/}
                        {/*          icon="delete"*/}
                        {/*          size="default"*/}
                        {/*        />*/}
                        {/*        {this.state.isCancelling &&*/}
                        {/*          this.isCancelling(record) &&*/}
                        {/*          <Spin*/}
                        {/*            style={{ marginLeft: 8 }}*/}
                        {/*            size="small"*/}
                        {/*          />}*/}
                        {/*      </span>*/}
                        {/*    </Popconfirm>}*/}
                        {/*</Mutation>*/}
                      </span>
                    )}
                  </div>
                )}
              </Mutation>
            </div>
          );
        },
      },
    ];
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.totalCount !== this.props.totalCount) {
      this.setState({
        pagination: {
          total: this.props.totalCount,
          current: this.state.page,
          pageSize: 10,
        },
      });
    }
  };

  handleColumnChange = (column: string) => {
    this.setState({
      column,
    });
  };

  handleGeneralSearch = async (searchText: string) => {
    const { column } = this.state;
    await this.props.onLoadMore(0, {
      searchText,
      column,
    });
    this.setState({
      searchText,
      page: 1,
      pagination: {
        current: 1,
        total: this.props.totalCount,
      },
    });
  };

  handleReset = async () => {
    await this.props.onLoadMore(0, {
      searchText: '',
      column: 'code',
    });
    this.setState({
      searchText: '',
      page: 1,
      pagination: {
        current: 1,
        total: this.props.totalCount,
      },
    });
  };

  isEditing = (record: any) => {
    return record.node.id === this.state.editingKey;
  };

  isCancelling = (record: any) => {
    return record.node.id === this.state.cancellingKey;
  };

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  onPromoCodeUpdated = async () => {
    const { searchText, column, page } = this.state;
    const skip = page > 1 ? (page - 1) * 10 : 0;
    await this.props.onLoadMore(skip, {
      searchText,
      column,
    });
    return this.setState({
      cancellingKey: '',
      editingKey: '',
      isUpdating: false,
      isCancelling: false,
    });
  };

  onMutationError = () => {
    return this.setState({
      isUpdating: false,
      editingKey: '',
      isCancelling: false,
      cancellingKey: '',
    });
  };

  submitPromoCodeUpdate = (
    form: any,
    record: any,
    promoCodeMutation: MutationFn<any, PromoCodeEditVariables>,
  ) => {
    form.validateFields((err: any, row: any) => {
      if (err) {
        return;
      }
      const promoCodeData = {
        ...row.node,
      };

      if (row.node.productIds && !Array.isArray(row.node.productIds)) {
        promoCodeData.productIds = row.node.productIds
          .split(/[ ,]+/)
          .filter(Boolean);
      } else if (
        !row.node.productIds &&
        Array.isArray(record.node.productIds)
      ) {
        promoCodeData.productIds = null;
      }
      if (row.node.usableByEmails && !Array.isArray(row.node.usableByEmails)) {
        promoCodeData.usableByEmails = row.node.usableByEmails
          .split(/[ ,]+/)
          .filter(Boolean);
      } else if (
        !row.node.usableByEmails &&
        Array.isArray(record.node.usableByEmails)
      ) {
        promoCodeData.usableByEmails = null;
      }
      if (
        row.node.isVoided &&
        (row.node.isVoided === 'true' || row.node.isVoided === 'false')
      ) {
        promoCodeData.isVoided = row.node.isVoided === 'true' || false;
      }

      if (
        row.node.isSingleUse &&
        (row.node.isSingleUse === 'true' || row.node.isSingleUse === 'false')
      ) {
        promoCodeData.isSingleUse = row.node.isSingleUse === 'true' || false;
      }
      if (
        row.node.blockCommissions &&
        (row.node.blockCommissions === 'true' ||
          row.node.blockCommissions === 'false')
      ) {
        promoCodeData.blockCommissions =
          row.node.blockCommissions === 'true' || false;
      }
      this.setState({ record, row, isUpdating: true });

      const variables: PromoCodeEditVariables = {
        input: {
          promoCodeId: record.node.promoCodeId,
          promoCodeData,
        },
      };
      return promoCodeMutation({ variables });
    });
  };

  // deletePayment = (record: any, deletePayment: (input: object) => void) => {
  //   if (this.state.isUpdating || this.state.isCancelling) return null;
  //   this.setState({ isCancelling: true, cancellingKey: record.node.id });
  //   const { selectedUserId } = this.props;
  //
  //   deletePayment({
  //     variables: {
  //       input: {
  //         selectedUserId,
  //         stripeTokenId: record.node.stripePayment.paymentId,
  //       },
  //     },
  //   });
  // };

  edit = (key: any) => {
    if (this.state.isUpdating) return null;
    this.setState({ editingKey: key.node.id });
  };

  getInputType = (key: string) => {
    switch (key) {
      case 'productIds':
      case 'usableByEmails':
        return 'textArea';
      case 'maxUses':
      case 'timesUsed':
      case 'storeBalance':
        return 'number';
      case 'expiresOn':
      case 'startsOn':
        return 'text';
      case 'blockCommissions':
        return 'blockCommissions';
      case 'isVoided':
        return 'isVoided';
      case 'isSingleUse':
        return 'isSingleUse';
      case 'categories':
        return 'categories';
      default:
        return 'text';
    }
  };

  onTableChange = (pagination: any) => {
    const { current, pageSize } = pagination;
    const fetchCurrent = current > 1 ? (current - 1) * pageSize : 0;
    this.props.onLoadMore(fetchCurrent, {
      searchText: this.state.searchText,
      column: this.state.column,
    });
    this.setState({
      page: current,
      pagination: {
        current,
        ...pagination,
      },
    });
  };

  handleSearchChange = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          key: col.key,
          title: col.title,
          inputType: this.getInputType(col.key),
          dataIndex: col.dataIndex,
          editing: this.isEditing(record),
        }),
      };
    });

    const { promoCodes, form, totalCount } = this.props;
    const scroll = { x: 3000 };

    return (
      <EditableContext.Provider value={form}>
        <AddGiftCardModal
          onLoadMore={this.props.onLoadMore}
          searchText={this.state.searchText}
          resetPagination={() =>
            this.setState({
              pagination: { current: 1, pageSize: 10, total: totalCount },
            })
          }
          column={this.state.column}
        />
        <Group compact>
          <Select
            defaultValue="code"
            style={{ width: 200, marginBottom: '1rem' }}
            onChange={this.handleColumnChange}
          >
            {this.columns.map((column) => (
              <Option value={column.key}>{column.title}</Option>
            ))}
          </Select>
          <Search
            style={{ width: 300 }}
            enterButton="Search"
            onSearch={this.handleGeneralSearch}
            allowClear
            onChange={this.handleSearchChange}
            value={this.state.searchText}
          />
          <Button
            type="primary"
            onClick={this.handleReset}
            style={{ borderLeft: '1px solid white' }}
          >
            Reset
          </Button>
        </Group>
        <Table
          bordered
          scroll={scroll}
          columns={columns}
          dataSource={promoCodes}
          components={components}
          onChange={this.onTableChange}
          rowKey={(row: any) => row.node.id}
          pagination={this.state.pagination}
          rowClassName="editable-row"
        />
      </EditableContext.Provider>
    );
  }
}
const EditableFormTable = Form.create()(PromoCodesTable);
export default EditableFormTable;
