import React from 'react';
import { css } from 'emotion';

type LineItemProps = {
  labelText: string;
  valueText: any;
};

const headerText = css`
  font-weight: bold;
`;

const LineItem = ({ labelText, valueText }: LineItemProps) => (
  <p>
    <span className={headerText}>{labelText}:</span> <span>{valueText}</span>
  </p>
);

export default LineItem;
