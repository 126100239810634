import React, { Component } from 'react';
import { Spin } from 'antd';
import { Query } from 'react-apollo';
import _get from 'lodash/get';

import Layout from '../components/MainLayout/layout/layout';
import Tabs from '../components/Tabs/Tabs';
import Addresses from '../components/Addresses';

import { getCurrentUserId } from '../utils/utils';
import GET_ADDRESSES_QUERY from '../queries/Addresses';

interface AddressesPageProps {
  location: any;
}

interface AddressesPageState {
  collapsed: boolean;
}

class AddressesPage extends React.Component<
  AddressesPageProps,
  AddressesPageState
> {
  state = {
    collapsed: false,
  };

  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout pathname={location.pathname} selectedUserId={currentUser}>
        <Query
          query={GET_ADDRESSES_QUERY}
          variables={{
            accountId: currentUser,
            csPortal: true,
          }}
        >
          {({ loading, error, data, refetch }) => {
            if (loading) return <Spin size="large" />;
            if (error) return `Error! ${error.message}`;
            const {
              viewer: {
                userInfo,
                userInfo: { firstName, lastName },
              },
            } = data;
            const isCeoSelected = _get(
              data.viewer.userInfo,
              'permissions.oneCommerce.ceo.access',
            );
            const userText = isCeoSelected ? 'Ceo' : 'Customer';

            const refetchFunction = () => {
              return refetch({
                accountId: currentUser,
                csPortal: true,
              });
            };
            return (
              <React.Fragment>
                <h3>
                  Viewing: {`${firstName} ${lastName}`} ({userText})
                </h3>
                <Tabs
                  isCeoSelected={isCeoSelected}
                  selectedValue="addresses"
                  selectedUserId={currentUser}
                />
                <Addresses
                  refetchFunction={refetchFunction}
                  selectedUserId={currentUser}
                  addresses={userInfo.addressBook.entries.edges}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default AddressesPage;
