import gql from 'graphql-tag';

const GET_DONATIONS_QUERY = gql`
  query ADash_DonationsQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        donations(first: 10000) {
          edges {
            node {
              id
              donationId
              createDate
              donationEndDate
              donationAmount
              status
              orderDonationAmount
              ordersSubTotalAmount
              npoId
              nonProfit {
                nonProfitName
                nonProfitTaxId
              }
              lobCheckId
              expectedCheckDeliveryDate
            }
          }
        }
      }
    }
  }
`;

export default GET_DONATIONS_QUERY;
