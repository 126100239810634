import isEmail from 'validator/lib/isEmail';

const checkForEmails = (rule, value, callback) => {
  if (value) {
    if (!Array.isArray(value)) {
      value = value.split(/[ ,]+/).filter(Boolean);
    }
    value.forEach(email => {
      if (!isEmail(email)) {
        callback('Invalid Email');
      }
    });
  }
  callback();
};

const validations = {
  'node.firstName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.lastName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.email': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        type: 'email',
        message: 'Not a valid e-mail',
      },
    ],
  },
  'node.maxUses': {
    rules: [
      {
        type: 'number',
        message: `Enter number`,
      },
    ],
  },
  'node.timesUsed': {
    rules: [
      {
        type: 'number',
        message: `Enter number`,
      },
    ],
  },
  'node.storeBalance': {
    rules: [
      {
        type: 'number',
        message: `Enter number`,
      },
    ],
  },
  'node.isVoided': {
    rules: [
      {
        type: 'enum',
        enum: ['true', 'false', false, true],
        message: `enter true or false`,
      },
    ],
  },
  'node.usableByEmails': {
    rules: [
      {
        validator: checkForEmails,
      },
    ],
  },
  'node.isSingleUse': {
    rules: [
      {
        type: 'enum',
        enum: ['true', 'false', false, true],
        message: `enter true or false`,
      },
    ],
  },
  'node.blockCommissions': {
    rules: [
      {
        type: 'enum',
        enum: ['true', 'false', false, true],
        message: `enter true or false`,
      },
    ],
  },
};

export default validations;
