import { statesAbbrev } from '../../../utils/utils';

const validations = {
  'node.shippingAddress.addressLineOne': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.shippingAddress.firstName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.shippingAddress.lastName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.shippingAddress.city': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.shippingAddress.state': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        type: 'enum',
        enum: statesAbbrev,
        message: `Invalid State`,
      },
    ],
  },
  'node.shippingAddress.zip': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        len: 5,
        message: `Invalid Zip`,
      },
    ],
  },
  'node.giftMessage.message': {
    rules: [
      {
        max: 200,
        message: `Max 200 chars`,
      },
    ],
  },
  'node.giftMessage.recipientName': {
    rules: [],
  },
  'node.giftMessage.from': {
    rules: [],
  },
};

export default validations;
