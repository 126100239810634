import React, { Component } from 'react';
import { Table, Form } from 'antd';
import { DateTime } from 'luxon';
import DonationMiniTable from './DonationMiniTable';

interface DonationsTableProps {
  donations: any;
  selectedUserId: string;
}

class DonationsTable extends Component<DonationsTableProps, {}> {
  private columns: Array<any>;
  constructor(props: DonationsTableProps) {
    super(props);
    this.columns = [
      {
        title: 'Donation ID',
        dataIndex: 'node.donationId',
        key: 'node.donationId',
        render: (text: string) => (text ? `${text.toString()}` : ''),
      },
      {
        title: 'Create Date',
        dataIndex: 'node.createDate',
        key: 'node.createDate',
        render: (text: string) =>
          text
            ? `${DateTime.fromISO(text).toLocaleString(DateTime.DATE_MED)}`
            : '',
      },
      {
        title: 'End Date',
        dataIndex: 'node.donationEndDate',
        key: 'node.donationEndDate',
        render: (text: string) =>
          text
            ? `${DateTime.fromISO(text).toLocaleString(DateTime.DATE_MED)}`
            : '',
      },
      {
        title: 'Donation Amount',
        dataIndex: 'node.donationAmount',
        key: 'node.donationAmount',
        render: (text: string) => (text ? `$${text.toString()}` : ''),
      },
      {
        title: 'Donation Status',
        dataIndex: 'node.status',
        key: 'node.status',
      },
      {
        title: 'Order Donation Amount',
        dataIndex: 'node.orderDonationAmount',
        key: 'node.orderDonationAmount',
        render: (text: string) => <DonationMiniTable data={text} />,
      },
      {
        title: 'Orders Sub Total Amount',
        dataIndex: 'node.ordersSubTotalAmount',
        key: 'node.ordersSubTotalAmount',
        render: (text: string) => <DonationMiniTable data={text} />,
      },
      {
        title: 'NPO ID',
        dataIndex: 'node.npoId',
        key: 'node.npoId',
      },
      {
        title: 'Non Profit',
        dataIndex: 'node.nonProfit.nonProfitName',
        key: 'node.nonProfit.nonProfitName',
      },
      {
        title: 'Non Profit Tax ID',
        dataIndex: 'node.nonProfit.nonProfitTaxId',
        key: 'node.nonProfit.nonProfitTaxId',
      },
      {
        title: 'Check ID',
        dataIndex: 'node.lobCheckId',
        key: 'node.lobCheckId',
      },
      {
        title: 'Expected Check Delivery Date',
        dataIndex: 'node.expectedCheckDeliveryDate',
        key: 'node.expectedCheckDeliveryDate',
        render: (text: string) =>
          text
            ? `${DateTime.fromISO(text).toLocaleString(DateTime.DATE_MED)}`
            : '',
      },
    ];
  }

  render() {
    const columns = this.columns.map(col => {
      return col;
    });

    const { donations } = this.props;
    const scroll = { x: 3000 };
    return (
      <Table
        bordered
        scroll={scroll}
        columns={columns}
        dataSource={donations}
        rowKey={(row: any) => row.node.id}
      />
    );
  }
}
const EditableFormTable = Form.create()(DonationsTable);
export default EditableFormTable;
