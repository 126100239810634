import React from 'react';
import { Tabs } from 'antd';
import Card from './Card';
import AutoshipCard from '../AutoshipCards/AutoshipCard';
import {
  ADash_SubscriptionsQuery_viewer_userInfo_autoShips,
  ADash_SubscriptionsQuery_viewer_userInfo_subscriptions,
} from '../../../queries/generatedTypes/ADash_SubscriptionsQuery';

const TabPane = Tabs.TabPane;

interface SubscriptionCardsProps {
  autoShips: ADash_SubscriptionsQuery_viewer_userInfo_autoShips[];
  selectedUserId: string;
  subscriptions: ADash_SubscriptionsQuery_viewer_userInfo_subscriptions;
  refetchSubscriptions: () => void;
}

const SubscriptionCards = (props: SubscriptionCardsProps) => {
  const { subscriptions, selectedUserId, autoShips } = props;
  const hasCEMembership =
    subscriptions && subscriptions.CE && subscriptions.CE.subscriptionId;

  const autoShipTabs = autoShips
    .sort((firstEl, secondEl) => +firstEl.autoShipId - +secondEl.autoShipId)
    .map(autoshipProps => (
      <TabPane
        tab={autoshipProps.autoShipName}
        key={`autoship-${autoshipProps.autoShipId}`}
      >
        <AutoshipCard
          autoShip={autoshipProps}
          refetchSubscriptions={props.refetchSubscriptions}
        />
      </TabPane>
    ));

  if (autoShipTabs.length === 0 && !hasCEMembership) {
    return (
      <div style={{ marginTop: 30 }}>
        <strong>No subscriptions found</strong>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginTop: 30 }}>
        <Tabs type="card" defaultActiveKey="1" tabPosition="top">
          {hasCEMembership && (
            <TabPane tab="CE Membership" key="3">
              <Card
                isCE
                subscription={subscriptions.CE}
                selectedUserId={selectedUserId}
              />
            </TabPane>
          )}
          {autoShipTabs}
        </Tabs>
      </div>
    </>
  );
};

export default SubscriptionCards;
