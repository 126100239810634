import React from 'react';

import Layout from '../components/MainLayout/layout/layout';
// import SEO from '../components/seo';

const NotFoundPage = ({ location: { pathname }, children }) => (
  <Layout selectedUserId={''} pathname={pathname} children={children}>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
