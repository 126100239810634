import React from 'react';
import PromoCodesTable from './PromoCodesTable/PromoCodesTable';

interface RefectchType {
  searchByCode: string;
  searchById: string;
}

interface PromoCodesTableProps {
  promoCodes: any;
  totalCount: number;
  refetchFunction: ({ variables }: { variables: RefectchType }) => void;
  onLoadMore: (input: number) => void;
}

const PromoCodes: React.FunctionComponent<PromoCodesTableProps> = ({
  totalCount,
  promoCodes,
  onLoadMore,
  refetchFunction,
  getPromoCodesByColumn,
}) => (
  <PromoCodesTable
    totalCount={totalCount}
    promoCodes={promoCodes}
    onLoadMore={onLoadMore}
    refetchFunction={refetchFunction}
    getPromoCodesByColumn={getPromoCodesByColumn}
  />
);
export default PromoCodes;
