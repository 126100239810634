import gql from 'graphql-tag';

const EDIT_ADDRESS_BOOK = gql`
  mutation AdminDash_AdressBookEdit($input: AddressBookEditInput!) {
    addressBookEdit(input: $input) {
      entryEdge {
        cursor
        node {
          id
          address {
            id
            disabled
            firstName
            lastName
            street
            addressLineOne
            addressLineTwo
            city
            state
            zip
            recipientBirthDate
            addressId
            country
            phoneNumber
            emailAddress
            specialInstructions
            nickname
            companyName
          }
        }
      }
      addressBookEntry {
        id
        address {
          id
          disabled
          firstName
          lastName
          street
          addressLineOne
          addressLineTwo
          city
          state
          zip
          recipientBirthDate
          addressId
          country
          phoneNumber
          emailAddress
          specialInstructions
          nickname
          companyName
        }
      }
      addressBook {
        id
        entries(first: 1000) {
          edges {
            __typename
            cursor
            node {
              id
              address {
                id
                disabled
                firstName
                lastName
                street
                addressLineOne
                addressLineTwo
                city
                state
                zip
                recipientBirthDate
                addressId
                country
                phoneNumber
                emailAddress
                specialInstructions
                nickname
                companyName
              }
            }
          }
        }
      }
      shippingAddressBook {
        id
        entries(first: 100) {
          edges {
            __typename
            cursor
            node {
              id
              address {
                id
                disabled
                firstName
                lastName
                street
                addressLineOne
                addressLineTwo
                city
                state
                zip
                recipientBirthDate
                addressId
                country
                phoneNumber
                emailAddress
                specialInstructions
                nickname
                companyName
              }
            }
          }
        }
      }
      address {
        id
        disabled
        firstName
        lastName
        street
        addressLineOne
        addressLineTwo
        city
        state
        zip
        recipientBirthDate
        addressId
        country
        phoneNumber
        emailAddress
        specialInstructions
        nickname
        companyName
      }
    }
  }
`;

export default EDIT_ADDRESS_BOOK;
