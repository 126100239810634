import React, { Component } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import EventsTable from './EventsTable/EventsTable';

const { RangePicker } = DatePicker;

interface EventsProps {
  events: any;
  selectedUserId: string;
}

interface EventsState {
  events: any;
}

class Events extends Component<EventsProps, EventsState> {
  constructor(props: EventsProps) {
    super(props);
    this.state = {
      events: props.events,
    };
  }

  onChange = (date: Array<any>, dateString: Array<string>) => {
    if (date && date.length > 0) {
      const filteredEvents = this.props.events.filter((event: any) => {
        const { eventDate } = event.node;
        const isAfter = moment(eventDate).isAfter(moment.utc(date[0]));
        const isBefore = moment(eventDate).isBefore(moment.utc(date[1]));
        return isAfter && isBefore;
      });
      return this.setState({ events: filteredEvents });
    }
    return this.setState({ events: this.props.events });
  };

  resetEvents = () => {
    this.setState({ events: this.props.events });
  };

  render() {
    const dateFormat = 'MM/DD/YYYY';
    const { selectedUserId } = this.props;
    const { events } = this.state;
    return (
      <React.Fragment>
        <RangePicker
          style={{ marginBottom: 20 }}
          format={dateFormat}
          onChange={this.onChange}
        />
        <EventsTable
          events={events}
          resetEvents={this.resetEvents}
          selectedUserId={selectedUserId}
        />
      </React.Fragment>
    );
  }
}

export default Events;
