import React from 'react';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import _get from 'lodash/get';
import AutoComplete from 'antd/lib/auto-complete';
import 'antd/lib/auto-complete/style/index.css';

import { navigate } from 'gatsby';
const InputGroup = Input.Group;
const Option = AutoComplete.Option;

interface HeaderAutoCompleteProps {
  data: any;
  loading: boolean;
  refetchUsers: (
    searchInput: string,
    email: boolean,
    firstName: boolean,
    lastName: boolean,
    trinityDealerId: boolean,
    accountId: boolean,
    orderId: boolean,
  ) => void;
  resetStore: (cb: () => void) => Promise<void>;
}

interface HeaderAutoCompleteState {
  email: boolean;
  firstName: boolean;
  lastName: boolean;
  trinityDealerId: boolean;
  accountId: boolean;
  orderId: boolean;
}

class HeaderAutoComplete extends React.Component<
  HeaderAutoCompleteProps,
  HeaderAutoCompleteState
> {
  constructor(props: HeaderAutoCompleteProps) {
    super(props);
    this.state = {
      firstName: false,
      lastName: false,
      trinityDealerId: false,
      email: true,
      accountId: false,
      orderId: false,
    };
  }

  shouldComponentUpdate(
    nextProps: Readonly<HeaderAutoCompleteProps>,
    nextState: Readonly<{}>,
  ): boolean {
    return !nextProps.loading;
  }

  handleSearch = (value: string) => {
    const { refetchUsers } = this.props;
    const {
      email,
      firstName,
      lastName,
      accountId,
      trinityDealerId,
      orderId,
    } = this.state;
    refetchUsers(
      value,
      email,
      firstName,
      lastName,
      trinityDealerId,
      accountId,
      orderId,
    );
  };

  onSelect = (value: any) => {
    if (value === 'noUsers') return;
    const { resetStore } = this.props;
    return resetStore(() => navigate(`/account-info?user=${value}`));
  };

  onOptionsSelect = (value: any) => {
    if (value === 'noUsers') return;
    if (value === 'firstName') {
      return this.setState({
        firstName: true,
        lastName: false,
        trinityDealerId: false,
        email: false,
        accountId: false,
        orderId: false,
      });
    } else if (value === 'lastName') {
      return this.setState({
        firstName: false,
        lastName: true,
        trinityDealerId: false,
        email: false,
        accountId: false,
        orderId: false,
      });
    } else if (value === 'trinityDealerId') {
      return this.setState({
        firstName: false,
        lastName: false,
        trinityDealerId: true,
        email: false,
        accountId: false,
        orderId: false,
      });
    } else if (value === 'email') {
      return this.setState({
        firstName: false,
        lastName: false,
        trinityDealerId: false,
        email: true,
        accountId: false,
        orderId: false,
      });
    } else if (value === 'accountId') {
      return this.setState({
        firstName: false,
        lastName: false,
        trinityDealerId: false,
        email: false,
        accountId: true,
        orderId: false,
      });
    } else if (value === 'orderId') {
      return this.setState({
        firstName: false,
        lastName: false,
        trinityDealerId: false,
        email: false,
        accountId: false,
        orderId: true,
      });
    }
  };

  renderOption = (item: any) => {
    if (item) {
      const { userId } = item.node;
      const isCeoSelected = _get(
        item.node,
        'permissions.oneCommerce.ceo.access',
      );
      const userText = isCeoSelected ? 'Ceo' : 'Customer';
      return (
        <Option
          key={userId}
          text={`${item.node.firstName} ${item.node.lastName}`}
        >
          <div className="global-search-item-count">
            {item.node.firstName} {item.node.lastName} ({userText})
          </div>
        </Option>
      );
    }
    return (
      <Option key="noUsers" text={''}>
        <div className="global-search-item-count">No users found</div>
      </Option>
    );
  };

  render() {
    const { data } = this.props;
    return (
      <div
        className="global-search-wrapper"
        style={{
          width: '100%',
          position: 'absolute',
          top: '25%',
          left: '100%',
          transform: 'translateX(-70%)',
        }}
      >
        <InputGroup compact>
          <Select
            defaultValue="email"
            style={{ width: 130 }}
            onSelect={this.onOptionsSelect}
          >
            <Option value="email">Email</Option>
            <Option value="firstName">First Name</Option>
            <Option value="lastName">Last Name</Option>
            <Option value="trinityDealerId">Trinity ID</Option>
            <Option value="accountId">ONEHOPE ID</Option>
            <Option value="orderId">Order #</Option>
          </Select>
          <AutoComplete
            className="global-search"
            style={{ width: 320 }}
            dataSource={data.map(this.renderOption)}
            onSelect={this.onSelect}
            onSearch={this.handleSearch}
            placeholder="Search for users by email, name, account id, or trinity id"
            optionLabelProp="text"
          />
        </InputGroup>
      </div>
    );
  }
}

export default HeaderAutoComplete;
