import React from 'react';
import CustomersTable from './CustomersTable/CustomersTable';

interface CustomersProps {
  customers: any;
  selectedUserId: string;
}

const Customers: React.FunctionComponent<CustomersProps> = ({
  customers,
  selectedUserId,
}) => <CustomersTable customers={customers} selectedUserId={selectedUserId} />;
export default Customers;
