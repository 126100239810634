import React from 'react';
import { Spin } from 'antd';
import { Query } from 'react-apollo';
import _get from 'lodash/get';

import Layout from '../components/MainLayout/layout/layout';
import Tabs from '../components/Tabs/Tabs';
import AccountInfo from '../components/Account/AccountInfo';

import { getCurrentUserId } from '../utils/utils';
import GET_ACCOUNT_INFO_QUERY from '../queries/AccountInfo';

interface AccountInfoPageProps {
  location: any;
}

interface AccountInfoPageState {
  collapsed: boolean;
}

class AccountInfoPage extends React.Component<
  AccountInfoPageProps,
  AccountInfoPageState
> {
  state = {
    collapsed: false,
  };

  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout pathname={location.pathname} selectedUserId={currentUser}>
        <Query
          query={GET_ACCOUNT_INFO_QUERY}
          variables={{
            accountId: currentUser,
            csPortal: true,
          }}
        >
          {({ loading, error, data }) => {
            if (loading) return <Spin size="large" />;
            if (error) return `Error! ${error.message}`;
            const {
              viewer: {
                userInfo,
                userInfo: { firstName, lastName },
              },
            } = data;
            const isCeoSelected = _get(
              data.viewer.userInfo,
              'permissions.oneCommerce.ceo.access',
            );
            const userText = isCeoSelected ? 'Ceo' : 'Customer';
            return (
              <React.Fragment>
                <h3>
                  Viewing: {`${firstName} ${lastName}`} ({userText})
                </h3>
                <Tabs
                  isCeoSelected={isCeoSelected}
                  selectedValue="account-info"
                  selectedUserId={currentUser}
                />
                <AccountInfo selectedUserId={currentUser} userInfo={userInfo} />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default AccountInfoPage;
