import gql from 'graphql-tag';

const WINE_CLUB_CANCEL_IMMEDIATE_MUTATION = gql`
  mutation WineClubCancelImmediateMutation(
    $input: WineClubCancelImmediateInput!
  ) {
    wineClubCancelImmediately(input: $input) {
      user {
        id
        userId
        email
        subscriptions {
          id
          wineClub {
            id
            marisCreationDate
            marisRenewalDate
            savings
            subscriptionStatus
            enrollmentStatus
            shippingAddress {
              id
              addressLineOne
              addressLineTwo
              city
              state
              zip
            }
            paymentInfo {
              id
              paymentId
              nickname
              brand
              last4
              expMonth
              expYear
            }
            subscriptionId
          }
        }
      }
    }
  }
`;

export default WINE_CLUB_CANCEL_IMMEDIATE_MUTATION;
