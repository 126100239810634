import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useLazyQuery } from '@apollo/react-hooks';

import Layout from '../components/MainLayout/layout/layout';
import PromoCodes from '../components/PromoCodes';
import GET_PROMO_CODES_BY_COLUMN from '../queries/promoCodesByColumn';

interface PromoCodesPageProps {
  location: any;
}

function getPromoCodeData(data: any) {
  return data?.viewer?.promoCodesByColumn?.edges;
}

function handleLoadMore({
  pageToLoad,
  fetchMore,
  variables,
  setPromoCodes,
}: any) {
  const newVariables = {
    ...variables,
  };
  if (pageToLoad) {
    newVariables.after = pageToLoad.toString();
  }
  return fetchMore({
    variables: newVariables,
    updateQuery: (prev, { fetchMoreResult }: any) => {
      if (!fetchMoreResult) return prev;
      const newEdges = fetchMoreResult?.viewer?.promoCodesByColumn?.edges;
      setPromoCodes(newEdges);
      return Object.assign({}, prev, {
        viewer: {
          ...fetchMoreResult.viewer,
          promoCodes: {
            ...fetchMoreResult.viewer.promoCodes,
            newEdges,
          },
        },
      });
    },
  });
}

export default function PromoCodesPagePage(props: PromoCodesPageProps) {
  const [promoCodes, setPromoCodes] = useState([]);

  const [
    getPromoCodesByColumn,
    { data, called, loading, variables, fetchMore },
  ] = useLazyQuery(GET_PROMO_CODES_BY_COLUMN, {
    onCompleted: d => {
      return setPromoCodes(getPromoCodeData(d));
    },
  });

  useEffect(() => {
    getPromoCodesByColumn({
      variables: {
        searchText: '',
        column: 'code',
      },
    });
  }, [getPromoCodesByColumn]);

  const onLoadMore = async (
    pageToLoad: number,
    newVariables?: { [key: string]: any },
  ) => {
    await handleLoadMore({
      pageToLoad,
      fetchMore,
      variables: newVariables ?? variables,
      setPromoCodes,
    });
  };

  const getPromoCodesByColumnPass = (variables: { [key: string]: any }) => {
    getPromoCodesByColumn({
      ...variables,
    });
  };

  return (
    <Layout pathname={props.location.pathname} selectedUserId={''}>
      <h3>Promo Codes</h3>
      {called && loading && <Spin size="large" />}
      {!loading && data?.viewer?.promoCodesByColumn && (
        <PromoCodes
          totalCount={promoCodes.length > 0 ? promoCodes[0].node.totalCount : 0}
          promoCodes={promoCodes}
          onLoadMore={onLoadMore}
          refetchFunction={getPromoCodesByColumnPass}
        />
      )}
    </Layout>
  );
}
