import gql from 'graphql-tag';

const GET_PROMO_CODES_BY_COLUMN_QUERY = gql`
  query ADash_PromoCodesColumPaginatedQuery(
    $searchText: String
    $column: String
    $after: String
  ) {
    viewer {
      id
      promoCodesByColumn(
        first: 10
        after: $after
        searchText: $searchText
        column: $column
      ) @connection(key: "promoCodesByColumn", filter: []) {
        edges {
          cursor
          node {
            id
            code
            totalCount
            promoCodeId
            startsOn
            credit
            createDate
            usableByAccountId
            maxUses
            storeBalance
            categories
            isVoided
            usableByEmails
            promoDescription
            discountShipping
            timesUsed
            discountTax
            productIds
            expiresOn
            credit
            blockCommissions
            isSingleUse
            excludeProducts {
              productId
              excluded
              discountShipping
            }
            storeBalanceActivity {
              date
              credit
            }
            thresholds {
              thresholdId
              discountFree
              discountTax
              discountShipping
              discountAmountEach
              spendQuantity
              discountFree
              spendAmount
            }
          }
        }
      }
    }
  }
`;

export default GET_PROMO_CODES_BY_COLUMN_QUERY;
