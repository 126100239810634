import React from 'react';
import PaymentsTable from './PaymentsTable/PaymentsTable';

interface PaymentsProps {
  payments: any;
  selectedUserId: string;
  refetchFunction: () => void;
}

const Payments: React.FunctionComponent<PaymentsProps> = ({
  payments,
  selectedUserId,
  refetchFunction,
}) => (
  <PaymentsTable
    refetchFunction={refetchFunction}
    payments={payments}
    selectedUserId={selectedUserId}
  />
);
export default Payments;
