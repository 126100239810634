import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Button, Popconfirm } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import LineItem from './LineItem';
import AutoShipCancelMutation from '../../../mutations/Autoship/AutoShipCancelMutation';
import { ADash_SubscriptionsQuery_viewer_userInfo_autoShips } from '../../../queries/generatedTypes/ADash_SubscriptionsQuery';

export type AutoshipCardProps = {
  autoShip: ADash_SubscriptionsQuery_viewer_userInfo_autoShips;
  refetchSubscriptions: () => void;
};

const ButtonContainer = styled.div`
  margin-top: 50px;
`;

const buildStripeLink = (subscriptionId: string) => {
  if (process.env.NODE_ENV === 'production') {
    return `https://dashboard.stripe.com/subscriptions/${subscriptionId}`;
  }
  return `https://dashboard.stripe.com/test/subscriptions/${subscriptionId}`;
};

const getPendingReason = ({
  pendingCuratedPack,
}: ADash_SubscriptionsQuery_viewer_userInfo_autoShips): string => {
  if (pendingCuratedPack) {
    return `Pending until next quarter's wine club is published`;
  }
  return `Pending creation (or something went wrong)`;
};

const formatDate = (date: string) => {
  return moment
    .utc(date)
    .local()
    .format('ll');
};

const AutoshipCard = (props: AutoshipCardProps) => {
  const {
    autoShipId,
    autoShipName,
    billingIntervalCount,
    billingIntervalUnit,
    createDate,
    lastModifiedDate,
    nextPaymentDate,
    curatedPackName,
    stripeSubscriptionId,
    subscriptionStatus,
    timesRenewed,
    winePack,
    nextShipmentIncludesPwP,
    isDefaultPwP,
  } = props.autoShip;

  const [isCancelling, setIsCancelling] = useState(false);

  const onMutationError = (e: Error) => {
    console.error('Mutation error', e);
    setIsCancelling(false);
  };

  const onCancelMutationCompleted = () => {
    setIsCancelling(false);
  };

  const cancelSubscription = async (
    autoshipCancel: (input: object) => void,
  ) => {
    if (isCancelling) return null;
    setIsCancelling(true);

    await autoshipCancel({
      variables: {
        input: {
          autoShipId,
        },
      },
    });
    props.refetchSubscriptions();
  };

  const getCuratedBottleCount = (curatedPackName?: string) => {
    switch (curatedPackName) {
      case 'wineclub12':
        return 12;
      case 'wineclub4':
        return 4;
      case 'wineclub':
        return 6;
      default:
        return null;
    }
  };

  const getPwpSelection = (
    nextShipmentIncludesPwP: boolean,
    isDefaultPwP: boolean,
  ) => {
    // Currently we dont support "skipping" PWP recurring add-on.
    if (nextShipmentIncludesPwP && !isDefaultPwP) {
      return 'one-time';
    }
    if (isDefaultPwP) {
      return 'recurring';
    }
    return 'no';
  };
  const stripeLink = stripeSubscriptionId ? (
    <a
      href={buildStripeLink(stripeSubscriptionId)}
      target="_blank"
      rel="noopener noreferrer"
    >
      View in Stripe
    </a>
  ) : (
    <em>{getPendingReason(props.autoShip)}</em>
  );

  const cancelButton = (
    <>
      <ButtonContainer>
        <Mutation
          mutation={AutoShipCancelMutation}
          onError={onMutationError}
          onCompleted={onCancelMutationCompleted}
        >
          {(autoshipCancel: (input: object) => void) => (
            <Popconfirm
              title={`Cancel ${autoShipName} for user? They will be notified.`}
              cancelText="No"
              okText="Yes"
              onConfirm={() => cancelSubscription(autoshipCancel)}
            >
              <Button
                style={{ marginTop: 20 }}
                htmlType="button"
                type="danger"
                title="Cancel"
                size="default"
                ghost={true}
                disabled={isCancelling}
              >
                Cancel {autoShipName}
              </Button>
            </Popconfirm>
          )}
        </Mutation>
      </ButtonContainer>
    </>
  );

  let wineNamesFull: Array<string> = [];
  let wineCount;
  if (winePack) {
    ({ wineNamesFull, wineCount } = winePack);
  }

  const bottleCount = getCuratedBottleCount(curatedPackName) || wineCount;

  const pwp = getPwpSelection(nextShipmentIncludesPwP, isDefaultPwP);

  return (
    <div style={{ marginTop: 30 }}>
      <LineItem
        labelText={'Subscription Status'}
        valueText={subscriptionStatus}
      />
      <LineItem labelText="Subscription Name" valueText={autoShipName} />
      <LineItem labelText="Autoship ID" valueText={autoShipId} />

      <LineItem labelText="Stripe Subscription" valueText={stripeLink} />
      <LineItem
        labelText="Included Wines"
        valueText={
          wineNamesFull.length ? (
            ''
          ) : (
            <em>{getPendingReason(props.autoShip)}</em>
          )
        }
      />

      <ul>
        {wineNamesFull.map(wine => (
          <li key={wine.toLowerCase().replace(/[^a-z]/g, '')}>{wine}</li>
        ))}
      </ul>

      <LineItem labelText="Bottle Count" valueText={bottleCount} />
      <LineItem labelText="PwP add-on" valueText={pwp} />
      <LineItem
        labelText="Frequency"
        valueText={`Every ${billingIntervalCount} ${billingIntervalUnit}(s)`}
      />
      <LineItem labelText="Create Date" valueText={formatDate(createDate)} />
      <LineItem
        labelText="Updated Date"
        valueText={formatDate(lastModifiedDate)}
      />
      <LineItem
        labelText="Next Bill Date"
        valueText={formatDate(nextPaymentDate)}
      />
      <LineItem labelText="Times Renewed" valueText={timesRenewed} />

      {subscriptionStatus !== 'canceled' && cancelButton}
      {subscriptionStatus === 'canceled' && (
        <ButtonContainer>
          <Button
            style={{ marginTop: 20 }}
            htmlType="button"
            type="danger"
            title="Already canceled"
            size="default"
            ghost={true}
            disabled={true}
          >
            Subscription has been canceled
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

export default AutoshipCard;
