import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/MainLayout/layout/layout';

class IndexPage extends React.Component<any, {}> {
  componentDidMount() {
    navigate('/promos');
  }
  render() {
    const {
      location: { pathname },
    } = this.props;
    return (
      <Layout
        pathname={pathname}
        selectedUserId={''}
        children={this.props.children}
      />
    );
  }
}

export default IndexPage;
