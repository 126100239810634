const validations = {
  'node.hostKitQuantity': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        transform: val => val.toString(),
      },
      {
        type: 'enum',
        enum: [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
        ],
        message: `Invalid Qty`,
      },
    ],
  },
  'node.hostKitOrderNumbers': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
};

export default validations;
