import { statesAbbrev } from '../../../utils/utils';

const validations = {
  'node.address.addressLineOne': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.address.firstName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.address.lastName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.address.city': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.address.state': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        type: 'enum',
        enum: statesAbbrev,
        message: `Invalid State`,
      },
    ],
  },
  'node.address.zip': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        len: 5,
        message: `Invalid Zip`,
      },
    ],
  },
  'node.address.phoneNumber': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        len: 10,
        message: `Invalid Phone#`,
      },
    ],
  },
};

export default validations;
