import gql from 'graphql-tag';

const GET_ADDRESSES_QUERY = gql`
  query ADash_AddressesQuery($accountId: String, $csPortal: Boolean!) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        role
        id
        userId
        firstName
        lastName
        permissions {
          id
          oneCommerce {
            ceo {
              access
            }
          }
        }
        addressBook {
          id
          totalNumberOfAddresses
          entries(first: 100)
            @connection(key: "AddressBook_entries", filter: []) {
            edges {
              __typename
              node {
                id
                address {
                  addressId
                  disabled
                  addressLineOne
                  addressLineTwo
                  city
                  companyName
                  country
                  firstName
                  id
                  lastName
                  specialInstructions
                  phoneNumber
                  recipientBirthDate
                  state
                  zip
                  nickname
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GET_ADDRESSES_QUERY;
