import React from 'react';
import OrdersTable from './OrdersTable/OrdersTable';
interface OrdersProps {
  orders: any;
  hasNextPage: boolean;
  onLoadMore: () => void;
  selectedUserId: string;
}

const Orders: React.FunctionComponent<OrdersProps> = ({
  orders,
  selectedUserId,
}) => <OrdersTable orders={orders} selectedUserId={selectedUserId} />;
export default Orders;
