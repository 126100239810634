import React from 'react';
import Layout from 'antd/lib/layout';
import { Query } from 'react-apollo';
import GET_SELECTED_USER_PERMISSIONS_QUERY from '../../../queries/SelectedUserPermissions';
import { whitelistedPaths } from '../../../utils/utils';
const { Content } = Layout;

interface MainContentProps {
  children: React.ReactNode;
  selectedUserId: string;
  pathname: string;
}

const contentStyle = {
  margin: '24px 16px',
  padding: 24,
  background: '#fff',
  minHeight: '100%',
};

const MainContent: React.FunctionComponent<MainContentProps> = ({
  selectedUserId,
  children,
  pathname,
}) => (
  <Query
    query={GET_SELECTED_USER_PERMISSIONS_QUERY}
    variables={{ accountId: selectedUserId, csPortal: true }}
  >
    {({ loading, error, refetch, data }) => {
      if (error) return `Error! ${error.message}`;
      if (loading) return <Content style={contentStyle} />;
      // @ts-ignore
      if (!data.viewer.userInfo.userId && !whitelistedPaths[pathname]) {
        return (
          <Content style={contentStyle}>
            <h1>User Not Found</h1>
          </Content>
        );
      }

      return <Content style={contentStyle}>{children}</Content>;
    }}
  </Query>
);

export default MainContent;
