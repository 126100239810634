import React from 'react';
import _get from 'lodash/get';
import { Query } from 'react-apollo';
import { Spin } from 'antd';

import Layout from '../components/MainLayout/layout/layout';
import Tabs from '../components/Tabs/Tabs';

import { getCurrentUserId } from '../utils/utils';
import Orders from '../components/Orders';
import GET_ORDERS_QUERY from './../queries/Orders';

interface OrdersPageProps {
  location: any;
}

class OrdersPage extends React.Component<OrdersPageProps, {}> {
  render() {
    const { location } = this.props;
    const currentUser = getCurrentUserId(location);

    return (
      <Layout pathname={location.pathname} selectedUserId={currentUser}>
        <Query
          query={GET_ORDERS_QUERY}
          variables={{
            accountId: currentUser,
            filterBy: 'any',
            sortBy: 'any',
            csPortal: true,
          }}
        >
          {({ loading, error, data, fetchMore }) => {
            if (loading) return <Spin size="large" />;
            if (error) return `Error! ${error.message}`;
            const onLoadMore = () =>
              fetchMore({
                variables: {
                  cursor: data.viewer.userInfo.orders.pageInfo.endCursor,
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  const newEdges = fetchMoreResult.viewer.userInfo.orders.edges;
                  const pageInfo =
                    fetchMoreResult.viewer.userInfo.orders.pageInfo;
                  return newEdges.length
                    ? {
                        // Put the new comments at the end of the list and update `pageInfo`
                        // so we have the new `endCursor` and `hasNextPage` values
                        viewer: {
                          ...previousResult.viewer,
                          userInfo: {
                            ...previousResult.viewer.userInfo,
                            orders: {
                              __typename:
                                previousResult.viewer.userInfo.orders
                                  .__typename,
                              edges: [
                                ...previousResult.viewer.userInfo.orders.edges,
                                ...newEdges,
                              ],
                              pageInfo,
                            },
                          },
                        },
                      }
                    : previousResult;
                },
              });
            const {
              viewer: {
                userInfo: { firstName, lastName, orders },
              },
            } = data;
            const isCeoSelected = _get(
              data.viewer.userInfo,
              'permissions.oneCommerce.ceo.access',
            );
            const userText = isCeoSelected ? 'Ceo' : 'Customer';
            return (
              <React.Fragment>
                <h3>
                  Viewing: {`${firstName} ${lastName}`} ({userText})
                </h3>
                <Tabs
                  isCeoSelected={isCeoSelected}
                  selectedValue="orders"
                  selectedUserId={currentUser}
                />
                <Orders
                  orders={orders.edges}
                  onLoadMore={onLoadMore}
                  selectedUserId={currentUser}
                  hasNextPage={orders.pageInfo.hasNextPage}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </Layout>
    );
  }
}

export default OrdersPage;
