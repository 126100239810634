const validations = {
  'node.firstName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.lastName': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
    ],
  },
  'node.email': {
    rules: [
      {
        required: true,
        message: `Required`,
      },
      {
        type: 'email',
        message: 'Not a valid e-mail',
      },
    ],
  },
};

export default validations;
