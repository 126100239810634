import React from 'react';

type DonationMiniTableProps = {
  data: any;
};

export default function DonationMiniTable(props: DonationMiniTableProps) {
  const { data } = props;
  const getTable = (data: any) => {
    if (!data) {
      return null;
    }
    const parsedData = JSON.parse(data);
    const objectData = Object.keys(parsedData);
    const borderStyle =
      objectData.length > 1
        ? {
            borderTop: '1px solid Gainsboro',
            borderBottom: '1px solid Gainsboro',
          }
        : {};
    return objectData.map(function(value) {
      return (
        <tr key={value} style={borderStyle}>
          <td>
            <span>{value}</span>
          </td>
          <td>
            <span>${parsedData[value]}</span>
          </td>
        </tr>
      );
    });
  };
  return (
    <table>
      <tbody>{getTable(data)}</tbody>
    </table>
  );
}
