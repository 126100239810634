import gql from 'graphql-tag';

const GET_SELECTED_USER_PERMISSIONS_QUERY = gql`
  query ADash_SelectedUserPermissionsQuery(
    $accountId: String
    $csPortal: Boolean!
  ) {
    viewer {
      id
      userInfo(accountId: $accountId, csPortal: $csPortal) {
        id
        userId
      }
    }
  }
`;

export default GET_SELECTED_USER_PERMISSIONS_QUERY;
