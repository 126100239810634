import React, { Component } from 'react';
import DonationsTable from './DonationsTable/DonationsTable';

interface DonationsProps {
  donations: any;
  selectedUserId: string;
}

interface DonationsState {
  donations: any;
}

class Donations extends Component<DonationsProps, DonationsState> {
  constructor(props: DonationsProps) {
    super(props);
    this.state = {
      donations: props.donations,
    };
  }

  render() {
    const { selectedUserId } = this.props;
    const { donations } = this.state;
    return (
      <React.Fragment>
        <DonationsTable donations={donations} selectedUserId={selectedUserId} />
      </React.Fragment>
    );
  }
}

export default Donations;
