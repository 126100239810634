import React, { Component } from 'react';
import { navigate } from 'gatsby';
import {
  Table,
  Input,
  Button,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Spin,
  Tooltip,
} from 'antd';
import Highlighter from 'react-highlight-words';
import { Mutation, MutationFn } from 'react-apollo';
const { TextArea } = Input;

import CustomerEditMutation from '../../../mutations/Customer/CustomerEditMutation';
import PaymentDeleteMutation from '../../../mutations/Payments/PaymentDelete';

import InputValidations from './InputValidations';

type CustomerEditMutationVariables = {
  input: {
    email: string;
    firstName: string;
    lastName: string;
    selectedUserId: string;
  };
};
const FormItem = Form.Item;
const EditableContext = React.createContext({});

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  index: number;
  record: any;
}

class EditableCell extends React.Component<EditableCellProps, {}> {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    } else if (this.props.inputType === 'textArea') {
      return <TextArea rows={4} />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      index,
      ...restProps
    } = this.props;
    const record = this.props.record ? this.props.record.node : undefined;
    const dataKey = dataIndex ? dataIndex.split('.')[1] : null;
    const rules = InputValidations[dataIndex]
      ? InputValidations[dataIndex].rules
      : undefined;
    return (
      <EditableContext.Consumer>
        {(form: any) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules,
                    initialValue: record[dataKey],
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

interface CustomersTableProps {
  form: any;
  customers: any;
  selectedUserId: string;
}

interface CustomersTableState {
  isUpdating: boolean;
  editingKey: string;
  cancellingKey: string;
  data: Array<any>;
  record: any;
  row: any;
  searchText: string;
  isCancelling: boolean;
}

class CustomersTable extends Component<
  CustomersTableProps,
  CustomersTableState
> {
  private columns: Array<any>;
  private searchInput: any;
  constructor(props: CustomersTableProps) {
    super(props);
    this.state = {
      data: props.customers,
      editingKey: '',
      cancellingKey: '',
      isUpdating: false,
      record: null,
      row: null,
      searchText: '',
      isCancelling: false,
    };
    this.columns = [
      {
        title: 'Account Id',
        dataIndex: 'node.accountId',
        editable: false,
        width: 360,
        key: 'accountId',
        ...this.getColumnSearchProps('node.accountId'),
        render: (text: string, record: any) => {
          const accountId = record.node.accountId;
          return (
            <a onClick={e => this.onCustomerClick(e, accountId)}>
              <Highlighter
                autoEscape
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[this.state.searchText]}
                textToHighlight={`${text ? text.toString() : ''}`}
              />
            </a>
          );
        },
      },
      {
        title: 'First Name',
        dataIndex: 'node.firstName',
        editable: true,
        key: 'firstName',
        sorter: (a: any, b: any) =>
          a.node.firstName.localeCompare(b.node.firstName),
        ...this.getColumnSearchProps('node.firstName'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        title: 'Last Name',
        dataIndex: 'node.lastName',
        editable: true,
        key: 'lastName',
        sorter: (a: any, b: any) =>
          a.node.lastName.localeCompare(b.node.lastName),
        ...this.getColumnSearchProps('node.lastName'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        title: 'Email',
        dataIndex: 'node.email',
        editable: true,
        key: 'email',
        sorter: (a: any, b: any) => a.node.email.localeCompare(b.node.email),
        ...this.getColumnSearchProps('node.email'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        width: '125px',
        fixed: 'right',
        render: (text: string, record: any) => {
          const editable = this.isEditing(record);
          const { editingKey } = this.state;
          return (
            <div>
              <Mutation
                mutation={CustomerEditMutation}
                onError={this.onMutationError}
                onCompleted={this.onCustomerUpdated}
              >
                {(editCustomer, { data }) => (
                  <div>
                    {editable ? (
                      <span>
                        <EditableContext.Consumer>
                          {form => (
                            <Popconfirm
                              title="Sure to save?"
                              cancelText="Close"
                              okText="Yes"
                              onConfirm={() =>
                                this.submitCustomerUpdate(
                                  form,
                                  record,
                                  editCustomer,
                                )
                              }
                            >
                              <Button
                                htmlType="button"
                                style={{ marginRight: 8 }}
                                type="primary"
                                shape="circle"
                                icon="save"
                                size="small"
                              />
                            </Popconfirm>
                          )}
                        </EditableContext.Consumer>
                        <React.Fragment>
                          <Button
                            onClick={this.cancel}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="close"
                            size="small"
                          />
                          {this.state.isUpdating && this.isEditing(record) && (
                            <Spin style={{ marginLeft: 8 }} size="small" />
                          )}
                        </React.Fragment>
                      </span>
                    ) : (
                      <span>
                        <Tooltip title="Edit">
                          <Button
                            onClick={() => this.edit(record)}
                            htmlType="button"
                            type="primary"
                            shape="circle"
                            icon="edit"
                            size="small"
                            disabled={editingKey !== ''}
                          />
                        </Tooltip>
                        {/* <Mutation
                            mutation={PaymentDeleteMutation}
                            onError={this.onMutationError}
                            onCompleted={this.onCustomerUpdated}>
                            {(deletePayment, { data }) =>
                              <Popconfirm
                                title="Delete Payment?"
                                cancelText="Close"
                                okText="Delete"
                                onConfirm={() =>
                                  this.deletePayment(record, deletePayment)}>
                                <span>
                                  <Button
                                    htmlType="button"
                                    style={{ marginLeft: 8 }}
                                    type="danger"
                                    shape="circle"
                                    icon="delete"
                                    size="default"
                                  />
                                  {this.state.isCancelling &&
                                    this.isCancelling(record) &&
                                    <Spin
                                      style={{ marginLeft: 8 }}
                                      size="small"
                                    />}
                                </span>
                              </Popconfirm>}
                          </Mutation> */}
                      </span>
                    )}
                  </div>
                )}
              </Mutation>
            </div>
          );
        },
      },
    ];
  }

  onCustomerClick = (e: React.ChangeEvent<any>, accountId: string) => {
    e.preventDefault();
    navigate(`/account-info?user=${accountId}`);
  };

  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex.split('.')[1]}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      const foundResult = record.node[dataIndex.split('.')[1]];
      if (!foundResult) {
        return 0;
      }
      return foundResult
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys: Array<string>, confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  isEditing = (record: any) => {
    return record.node.id === this.state.editingKey;
  };

  isCancelling = (record: any) => {
    return record.node.id === this.state.cancellingKey;
  };

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  onCustomerUpdated = () => {
    return this.setState({
      cancellingKey: '',
      editingKey: '',
      isUpdating: false,
      isCancelling: false,
    });
  };

  onMutationError = () => {
    return this.setState({
      isUpdating: false,
      editingKey: '',
      isCancelling: false,
      cancellingKey: '',
    });
  };

  submitCustomerUpdate = (
    form: any,
    record: any,
    customerEditMutation: MutationFn<any, CustomerEditMutationVariables>,
  ) => {
    this.setState({ isUpdating: true });
    form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }
      const { email, firstName, lastName } = values.node;
      const variables: CustomerEditMutationVariables = {
        input: {
          email,
          firstName,
          lastName,
          selectedUserId: record.node.accountId,
        },
      };
      return customerEditMutation({ variables });
    });
  };

  deletePayment = (record: any, deletePayment: (input: object) => void) => {
    if (this.state.isUpdating || this.state.isCancelling) return null;
    this.setState({ isCancelling: true, cancellingKey: record.node.id });
    const { selectedUserId } = this.props;

    deletePayment({
      variables: {
        input: {
          selectedUserId,
          stripeTokenId: record.node.stripePayment.paymentId,
        },
      },
    });
  };

  edit = (key: any) => {
    if (this.state.isUpdating) return null;
    this.setState({ editingKey: key.node.id });
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          key: col.key,
          title: col.title,
          inputType: 'text',
          dataIndex: col.dataIndex,
          editing: this.isEditing(record),
        }),
      };
    });

    const { customers, form } = this.props;
    const scroll = { x: 1200 };

    return (
      <EditableContext.Provider value={form}>
        <Table
          bordered
          scroll={scroll}
          columns={columns}
          dataSource={customers}
          components={components}
          rowKey={(row: any) => row.node.id}
          rowClassName="editable-row"
        />
      </EditableContext.Provider>
    );
  }
}
const EditableFormTable = Form.create()(CustomersTable);
export default EditableFormTable;
