import React, { Component } from 'react';
import { Modal, Button, Form, InputNumber } from 'antd';
import { Mutation, MutationFn } from 'react-apollo';
import GiftCardAddMutation from '../../../mutations/PromoCodes/GiftCardAddMutation';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

interface AddGiftCardModalProps {
  form?: any;
  onLoadMore: (input: number) => void;
  resetPagination: () => void;
  searchText: string;
  column: string;
}

interface AddGiftCardModalState {
  visible: boolean;
  ModalText: string;
  confirmLoading: boolean;
}
class AddGiftCardModal extends Component<
  AddGiftCardModalProps,
  AddGiftCardModalState
> {
  state = {
    ModalText: 'Create Gift Card',
    visible: false,
    confirmLoading: false,
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (giftCardAddMutation: any) => {
    this.props.form.validateFields((err: any, values: any) => {
      if (err) {
        return;
      }

      this.setState({
        confirmLoading: true,
      });
      const { quantity, amount } = values;
      const { searchText, column } = this.props;

      const variables = {
        input: {
          quantity,
          amount,
          searchText,
          column,
        },
      };
      return giftCardAddMutation({ variables });
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onSuccess = () => {
    const { onLoadMore } = this.props;
    this.setState(
      {
        visible: false,
        confirmLoading: false,
      },
      async () => {
        await onLoadMore(0, {
          searchText: this.props.searchText,
          column: this.props.column,
        });
        this.props.resetPagination();
      },
    );
  };

  onFailure = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
    });
  };

  render() {
    const { visible, confirmLoading, ModalText } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Button
          htmlType="button"
          style={{ marginBottom: 16 }}
          type="primary"
          onClick={this.showModal}
        >
          {ModalText}
        </Button>
        <Mutation
          mutation={GiftCardAddMutation}
          onError={this.onFailure}
          onCompleted={this.onSuccess}
        >
          {(giftCardAddMutation, { data }) => {
            return (
              <Modal
                title={ModalText}
                visible={visible}
                okText="Save"
                onOk={() => this.handleOk(giftCardAddMutation)}
                confirmLoading={confirmLoading}
                onCancel={this.handleCancel}
              >
                <div>
                  <Form layout="horizontal">
                    <Form.Item {...formItemLayout} label="Amount($)">
                      {getFieldDecorator('amount', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input an amount',
                          },
                        ],
                      })(<InputNumber />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout} label="Quantity">
                      {getFieldDecorator('quantity', {
                        rules: [
                          {
                            required: true,
                            message: 'Please input quantity',
                          },
                        ],
                      })(<InputNumber />)}
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            );
          }}
        </Mutation>
      </div>
    );
  }
}

const WrappedAddGiftCardModal = Form.create({ name: 'add_gift_card' })(
  AddGiftCardModal,
);
export default WrappedAddGiftCardModal;
