type AddressFormType = {
  name: string;
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: string;
};
type CardType = {
  nickname: string;
  cardNumber: string;
  expYear: string;
  expMonth: string;
  cvc: string;
};
/* eslint-disable */
export function addressToStripeAddress({
  name = '',
  city = '',
  addressLineOne = '',
  addressLineTwo = '',
  state = '',
  zip = '',
}: AddressFormType): Object {
  return {
    name: name,
    address_city: city,
    address_country: 'US',
    address_line1: addressLineOne,
    address_line2: addressLineTwo,
    address_state: state,
    address_zip: zip,
  };
}

export function expirationDateToStripeExpiration({
  expYear,
  expMonth,
}: CardType): Object {
  return {
    exp_month: expMonth,
    exp_year: expYear,
  };
}
