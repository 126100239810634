import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Spin,
  Button,
  Tooltip,
} from 'antd';
import Highlighter from 'react-highlight-words';
const { TextArea } = Input;

import OrderCancelMutation from '../../../mutations/Orders/OrderCancelMutation';
import OrderEditMutation from '../../../mutations/Orders/OrderEditMutation';
import InputValidations from './InputValidations';
import { OrderStatus } from '../../../utils/orderConstants';

const FormItem = Form.Item;
const EditableContext = React.createContext({});

const highLightStyle = { backgroundColor: '#ffc069', padding: 0 };

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  index: number;
  record: any;
}

class EditableCell extends React.Component<EditableCellProps, {}> {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    } else if (this.props.inputType === 'textArea') {
      return <TextArea rows={4} />;
    }
    return <Input />;
  };

  render() {
    const {
      title,
      editing,
      dataIndex,
      inputType,
      index,
      ...restProps
    } = this.props;
    const record = this.props.record ? this.props.record.node : undefined;
    let initialValue = null;
    if (
      record &&
      record.shippingAddress &&
      dataIndex &&
      dataIndex.split('.')[1] === 'shippingAddress'
    ) {
      const dataKey = dataIndex.split('.')[2];
      initialValue = record.shippingAddress[dataKey];
    } else if (
      record &&
      record.giftMessage &&
      dataIndex &&
      dataIndex.split('.')[1] === 'giftMessage'
    ) {
      const dataKey = dataIndex.split('.')[2];
      initialValue = record.giftMessage[dataKey];
    } else if (record && dataIndex) {
      const dataKey = dataIndex.split('.')[1];
      initialValue = record[dataKey];
    }
    const rules = InputValidations[dataIndex]
      ? InputValidations[dataIndex].rules
      : undefined;
    return (
      <EditableContext.Consumer>
        {(form: any) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: rules,
                    initialValue: initialValue,
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

interface OrdersTableProps {
  form: any;
  orders: any;
  selectedUserId: string;
}

interface OrdersTableState {
  isUpdating: boolean;
  editingKey: string;
  data: Array<any>;
  record: any;
  row: any;
  searchText: string;
  cancellingKey: string;
  isCancelling: boolean;
}

class OrdersTable extends Component<OrdersTableProps, OrdersTableState> {
  private columns: Array<any>;
  private searchInput: any;
  private form: any;
  constructor(props: OrdersTableProps) {
    super(props);
    this.state = {
      data: props.orders,
      editingKey: '',
      isUpdating: false,
      record: null,
      row: null,
      searchText: '',
      cancellingKey: '',
      isCancelling: false,
    };

    this.columns = [
      {
        title: 'Order #',
        dataIndex: 'node.orderNumber',
        editable: false,
        key: 'orderNumber',
        // width: 250,
        // fixed: 'left',
        sorter: (a: any, b: any) =>
          a.node.orderNumber.localeCompare(b.node.orderNumber),
        render: (text: string, record: any) => {
          const route = `order/${record.node.orderId}`;
          return (
            <a href={`https://onehopewine.com/${route}`} target="_blank">
              <Highlighter
                autoEscape
                highlightStyle={highLightStyle}
                searchWords={[this.state.searchText]}
                textToHighlight={`${text ? text.toString() : ''}`}
              />
            </a>
          );
        },
        ...this.getColumnSearchProps('node.orderNumber'),
      },
      {
        title: 'Date',
        dataIndex: 'node.createDate',
        editable: false,
        key: 'createDate',
        sorter: (a: any, b: any) =>
          a.node.createDate.localeCompare(b.node.createDate),
        render: (text: string, record: any) => {
          const date = moment
            .utc(record.node.createDate)
            .local()
            .format('ll');
          return (
            <Highlighter
              autoEscape
              highlightStyle={highLightStyle}
              searchWords={[this.state.searchText]}
              textToHighlight={`${date}`}
            />
          );
        },
      },
      {
        title: 'Name',
        dataIndex: 'node.accountFullName',
        editable: false,
        key: 'accountFullName',
        render: (text: string, record: any) => {
          return (
            <Highlighter
              autoEscape
              highlightStyle={highLightStyle}
              searchWords={[this.state.searchText]}
              textToHighlight={text ? `${text.toString()}` : ''}
            />
          );
        },
        ...this.getColumnSearchProps('node.accountFullName'),
      },
      {
        title: 'Email',
        dataIndex: 'node.email',
        editable: true,
        key: 'email',
        sorter: (a: any, b: any) => a.node.email.localeCompare(b.node.email),
        render: (text: string, record: any) => {
          return (
            <Highlighter
              autoEscape
              highlightStyle={highLightStyle}
              searchWords={[this.state.searchText]}
              textToHighlight={text ? `${text.toString()}` : ''}
            />
          );
        },
        ...this.getColumnSearchProps('node.email'),
      },
      {
        title: 'Total',
        dataIndex: 'node.orderTotal',
        editable: false,
        key: 'orderTotal',
        sorter: (a: any, b: any) => a.node.orderTotal - b.node.orderTotal,
        render: (text: string) => {
          return (
            <Highlighter
              autoEscape
              highlightStyle={highLightStyle}
              searchWords={[this.state.searchText]}
              textToHighlight={`${text ? `$${text.toString()}` : ''}`}
            />
          );
        },
        ...this.getColumnSearchProps('node.orderTotal'),
      },
      {
        title: 'Status',
        dataIndex: 'node.orderStatus',
        key: 'status',
        editable: false,
        sorter: (a: any, b: any) =>
          a.node.orderStatus.localeCompare(b.node.orderStatus),
        render: (text: string) => {
          return (
            <Highlighter
              autoEscape
              highlightStyle={highLightStyle}
              searchWords={[this.state.searchText]}
              textToHighlight={`${text ? text.toString() : ''}`}
            />
          );
        },
      },
      {
        title: 'Tracking',
        dataIndex: 'node.shipments',
        editable: false,
        key: 'trackingNumber',
        render: shipments => {
          if (!shipments || shipments.length === 0) return null;
          const trackingNumbers = shipments.map(shipment => {
            const trackingLink = shipment?.shipmentInformation?.trackingLink;
            if (!trackingLink) {
              return null;
            }
            const carrier = shipment?.shipmentInformation?.carrier;
            const trackingNums = shipment?.shipmentInformation?.tracking?.toString();
            return (
              <>
                <a href={trackingLink} target="_blank">
                  <Highlighter
                    autoEscape
                    highlightStyle={highLightStyle}
                    searchWords={[this.state.searchText]}
                    textToHighlight={`${carrier}: ${trackingNums}`}
                  />
                </a>
                <br />
              </>
            );
          });
          return trackingNumbers;
        },
        ...this.getColumnSearchProps('node.orderNumber'),
      },
      {
        title: 'Trinity Order Id',
        dataIndex: 'node.trinityOrderId',
        editable: false,
        key: 'trinityOrderId',
        // sorter: (a: any, b: any) =>
        //   a.node.trinityOrderId.localeCompare(b.node.trinityOrderId),
        render: (text: string) => {
          const textToHighlight = text ? text.toString() : '';
          return (
            <Highlighter
              autoEscape
              highlightStyle={highLightStyle}
              searchWords={[this.state.searchText]}
              textToHighlight={`${textToHighlight}`}
            />
          );
        },
        ...this.getColumnSearchProps('node.trinityOrderId'),
      },
      {
        title: 'Trinity Party Id',
        dataIndex: 'node.trinityPartyId',
        editable: false,
        key: 'trinityPartyId',
      },
      {
        title: 'Trinity Status',
        dataIndex: 'node.trinityTransmissionStatus',
        editable: false,
        key: 'trinityStatus',
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>First Name</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.firstName',
        editable: true,
        key: 'shippingAddress.firstName',
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>Last Name</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.lastName',
        key: 'shippingAddress.lastName',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>Address Line 1</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.addressLineOne',
        key: 'shippingAddress.addressLineOne',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>Address Line 2</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.addressLineTwo',
        key: 'shippingAddress.addressLineTwo',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>City</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.city',
        key: 'shippingAddress.city',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>State</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.state',
        key: 'shippingAddress.state',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Shipping Address</div>
            <div>Zip</div>
          </React.Fragment>
        ),
        dataIndex: 'node.shippingAddress.zip',
        key: 'shippingAddress.zip',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Gift Message</div>
            <div>Note</div>
          </React.Fragment>
        ),
        dataIndex: 'node.giftMessage.message',
        inputType: 'textArea',
        editable: true,
        key: 'giftMessage',
        width: 200,
        render: (text: string) => {
          return text
            ? text.length > 100
              ? `${text.slice(0, 100)}...`
              : text
            : '';
        },
      },
      {
        title: () => (
          <React.Fragment>
            <div>Gift Message</div>
            <div>Recipient Name</div>
          </React.Fragment>
        ),
        dataIndex: 'node.giftMessage.recipientName',
        key: 'recipientName',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Gift Message</div>
            <div>From</div>
          </React.Fragment>
        ),
        dataIndex: 'node.giftMessage.from',
        key: 'from',
        editable: true,
      },
      {
        title: () => (
          <React.Fragment>
            <div>Cause of Choice</div>
          </React.Fragment>
        ),
        dataIndex: 'node.nonProfit.nonProfitName',
        key: 'nonProfit',
        editable: false,
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        editable: false,
        width: '170px',
        fixed: 'right',
        render: (text: string, record: any) => {
          const editable = this.isEditing(record);
          const { editingKey } = this.state;
          if (record.node.orderStatus !== OrderStatus.orderReceived)
            return null;
          return (
            <div>
              <Mutation
                mutation={OrderEditMutation}
                onError={this.onMutationError}
                onCompleted={this.onOrderUpdated}
              >
                {(editOrder, { data }) => (
                  <div>
                    {editable ? (
                      <span>
                        <EditableContext.Consumer>
                          {form => {
                            return (
                              <Popconfirm
                                title="Sure to save?"
                                cancelText="Close"
                                okText="Yes"
                                onConfirm={() =>
                                  this.save(form, record, editOrder)
                                }
                              >
                                <Button
                                  htmlType="button"
                                  style={{ marginRight: 8 }}
                                  type="primary"
                                  shape="circle"
                                  icon="save"
                                  size="small"
                                />
                              </Popconfirm>
                            );
                          }}
                        </EditableContext.Consumer>
                        <React.Fragment>
                          <Tooltip title="Close">
                            <Button
                              onClick={this.close}
                              htmlType="button"
                              type="default"
                              shape="circle"
                              icon="close"
                              size="small"
                            />
                          </Tooltip>
                          {this.state.isUpdating && this.isEditing(record) && (
                            <Spin style={{ marginLeft: 8 }} size="small" />
                          )}
                        </React.Fragment>
                      </span>
                    ) : (
                      <span>
                        <Tooltip title="Edit">
                          <Button
                            disabled={editingKey !== ''}
                            onClick={() => this.edit(record)}
                            htmlType="button"
                            type="primary"
                            shape="circle"
                            icon="edit"
                            size="small"
                          />
                        </Tooltip>
                        {/* <Mutation
                          mutation={OrderCancelMutation}
                          onError={this.onMutationError}
                          onCompleted={this.onOrderUpdated}
                        >
                          {(cancelOrder, { data }) => (
                            <Popconfirm
                              title="Cancel Order?"
                              cancelText="Close"
                              okText="Yes"
                              onConfirm={() =>
                                this.cancelOrder(record, cancelOrder)
                              }
                            >
                              <span>
                                <Button
                                  htmlType="button"
                                  style={{ marginLeft: 8 }}
                                  type="danger"
                                  shape="circle"
                                  icon="delete"
                                  size="default"
                                />
                                {this.state.isCancelling &&
                                  this.isCancelling(record) && (
                                    <Spin
                                      style={{ marginLeft: 8 }}
                                      size="small"
                                    />
                                  )}
                              </span>
                            </Popconfirm>
                          )}
                        </Mutation> */}
                        {/* <Tooltip title="Move">
                          <Button
                            style={{ marginLeft: 8 }}
                            onClick={() => {}}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="drag"
                            size="small"
                          />
                        </Tooltip> */}
                        {/* <Tooltip title="Reship">
                          <Button
                            style={{ marginLeft: 8 }}
                            onClick={() => {}}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="redo"
                            size="small"
                          />
                        </Tooltip> */}
                      </span>
                    )}
                  </div>
                )}
              </Mutation>
            </div>
          );
        },
      },
    ];
  }

  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex.split('.')[1]}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      const filterVal = record.node[dataIndex.split('.')[1]];
      return filterVal
        ? filterVal
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : null;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys: Array<string>, confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  isEditing = (record: any) => {
    return record.node.id === this.state.editingKey;
  };

  isCancelling = (record: any) => {
    return record.node.id === this.state.cancellingKey;
  };

  close = () => {
    this.setState({ editingKey: '', cancellingKey: '' });
  };

  onOrderUpdated = () => {
    return this.setState({
      isUpdating: false,
      isCancelling: false,
      editingKey: '',
      cancellingKey: '',
    });
  };

  onMutationError = () => {
    return this.setState({
      isUpdating: false,
      editingKey: '',
      isCancelling: false,
      cancellingKey: '',
    });
  };

  save = (form: any, record: any, editOrder: (input: object) => void) => {
    if (!form || this.state.isUpdating || this.state.isCancelling) return null;
    form.validateFields((error: Object, row: any) => {
      if (error) {
        return;
      }

      const { selectedUserId } = this.props;
      this.setState({ record, row, isUpdating: true });

      editOrder({
        variables: {
          input: {
            selectedUserId,
            orderId: record.node.orderId,
            form: row.node,
          },
        },
      });
    });
  };

  cancelOrder = (record: any, cancelOrder: (input: object) => void) => {
    if (this.state.isUpdating || this.state.isCancelling) return null;
    this.setState({ isCancelling: true, cancellingKey: record.node.id });
    cancelOrder({
      variables: { input: { orderId: record.node.orderId } },
    });
  };

  edit = (key: any) => {
    if (this.state.isUpdating) return null;
    this.setState({ editingKey: key.node.id });
  };

  // onPageChange = (page: number, pageSize: number) => {
  //   const { onLoadMore, hasNextPage } = this.props;
  //   if (hasNextPage) {
  //     onLoadMore();
  //   }
  // };

  render() {
    const components = {
      body: {
        // row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          key: col.key,
          title: col.title,
          inputType:
            col.dataIndex === 'node.giftMessage.message' ? 'textArea' : 'text',
          dataIndex: col.dataIndex,
          editing: this.isEditing(record),
        }),
      };
    });

    const { orders, form } = this.props;
    const scroll = { x: 3600 };
    return (
      <EditableContext.Provider value={form}>
        <Table
          bordered
          scroll={scroll}
          columns={columns}
          dataSource={orders}
          components={components}
          rowKey={(row: any) => row.node.id}
          rowClassName="editable-row"
        />
      </EditableContext.Provider>
    );
  }
}
const EditableFormTable = Form.create()(OrdersTable);
export default EditableFormTable;
