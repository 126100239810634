import React, { Component } from 'react';
import {
  Table,
  Input,
  Button,
  InputNumber,
  Popconfirm,
  Form,
  Icon,
  Spin,
  Tooltip,
} from 'antd';
import moment from 'moment';
import { Link } from 'gatsby';

import Highlighter from 'react-highlight-words';
import { Mutation } from 'react-apollo';
import EventEditMutation from '../../../mutations/Event/EventEditMutation';

import InputValidations from './InputValidations';
import { EventManagementTabs } from '../../../utils/constants';
const { TextArea } = Input;

const FormItem = Form.Item;
const EditableContext = React.createContext({});

//#region Editable Cell

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: string;
  index: number;
  record: any;
}

class EditableCell extends React.Component<EditableCellProps, {}> {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    } else if (this.props.inputType === 'textArea') {
      return <TextArea rows={4} />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      index,
      ...restProps
    } = this.props;
    const record = this.props.record ? this.props.record.node : undefined;

    let initialValue = null;
    if (
      record &&
      record.donationInfo &&
      dataIndex &&
      dataIndex.split('.')[2] === 'event'
    ) {
      const dataKey = dataIndex.split('.')[3];
      initialValue = record.donationInfo[dataKey];
    } else if (record && dataIndex) {
      const dataKey = dataIndex.split('.')[1];
      initialValue = record[dataKey];
    }
    const rules = InputValidations[dataIndex]
      ? InputValidations[dataIndex].rules
      : undefined;
    return (
      <EditableContext.Consumer>
        {(form: any) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules,
                    initialValue,
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

//#region Events Table State

interface EventsTableState {
  isUpdating: boolean;
  editingKey: string;
  cancellingKey: string;
  data: Array<any>;
  record: any;
  row: any;
  searchText: string;
  isCancelling: boolean;
}

//#region Events Table Component
interface EventsTableProps {
  events: any;
  selectedUserId: string;
  resetEvents: () => void;
  form: any;
}

class EventsTable extends Component<EventsTableProps, EventsTableState> {
  private columns: Array<any>;
  private searchInput: any;
  constructor(props: EventsTableProps) {
    super(props);
    this.state = {
      data: props.events,
      editingKey: '',
      cancellingKey: '',
      isUpdating: false,
      record: null,
      row: null,
      searchText: '',
      isCancelling: false,
    };
    this.columns = [
      {
        title: 'Date',
        dataIndex: 'node.eventDate',
        editable: false,
        key: 'eventDate',
        sorter: (a: any, b: any) =>
          a.node.eventDate.localeCompare(b.node.eventDate),
        render: (text: string) => {
          const date = moment
            .utc(text)
            .local()
            .format('ll');
          return `${date}`;
        },
      },
      {
        title: 'Exigo Event ID',
        dataIndex: 'node.exigoPartyId',
        editable: false,
        key: 'exigoPartyId',
        ...this.getColumnSearchProps('node.exigoPartyId'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        title: 'Event Id',
        dataIndex: 'node.eventId',
        editable: false,
        key: 'eventId',
        ...this.getColumnSearchProps('node.eventId'),
        render: (text: string) => (
          <a
            href={`${process.env.GATSBY_CE_DASH_URL}/event?eventId=${text}&tab=${EventManagementTabs.SUMMARY}`}
            target="_blank"
          >
            <Highlighter
              autoEscape
              onClick={() => {}}
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              textToHighlight={`${text ? text.toString() : ''}`}
            />
          </a>
        ),
      },
      {
        title: 'Host First Name',
        dataIndex: 'node.hostFirstName',
        editable: false,
        key: 'hostFirstName',
        sorter: (a: any, b: any) =>
          a.node.hostFirstName.localeCompare(b.node.hostFirstName),
        ...this.getColumnSearchProps('node.hostFirstName'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        title: 'Host Last Name',
        dataIndex: 'node.hostLastName',
        editable: false,
        key: 'hostLastName',
        sorter: (a: any, b: any) => {
          if (a.node.hostLastName != null && b.node.hostLastName != null) {
            return a.node.hostLastName.localeCompare(b.node.hostLastName);
          }
          return 0;
        },
        ...this.getColumnSearchProps('node.hostLastName'),
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        title: 'Host Email',
        dataIndex: 'node.hostEmail',
        editable: false,
        key: 'hostEmail',
        render: (text: string) => (
          <Highlighter
            autoEscape
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            textToHighlight={`${text ? text.toString() : ''}`}
          />
        ),
      },
      {
        title: 'Host Account Id',
        dataIndex: 'node.hostAccountId',
        editable: false,
        key: 'hostAccountId',
        render: (hostAccountId: string) => (
          <Link to={`/account-info?user=${hostAccountId}`}>
            {hostAccountId ? hostAccountId.toString() : ''}
          </Link>
        ),
      },
      {
        title: 'Close Date',
        dataIndex: 'node.closeDate',
        editable: false,
        key: 'closeDate',
        // sorter: (a: any, b: any) => {
        //   if (a.node.closeDate != null && b.node.closeDate != null) {
        //     return a.node.closeDate.localeCompare(b.node.closeDate);
        //   }
        //   return null;
        // },
        render: (text: string) => {
          const date = moment
            .utc(text)
            .local()
            .format('ll');
          return `${date}`;
        },
      },
      {
        title: 'Event Status',
        dataIndex: 'node.status',
        editable: false,
        key: 'status',
      },
      {
        title: 'Donation Status',
        dataIndex: 'node.donationInfo.status',
        editable: false,
        key: 'donationInfo.status',
      },
      {
        title: 'Donation Amount',
        dataIndex: 'node.donationInfo.donationAmount',
        editable: false,
        key: 'donationInfo.donationAmount',
        sorter: (a: any, b: any) => {
          const first =
            a.node.donationInfo && a.node.donationInfo.donationAmount
              ? a.node.donationInfo.donationAmount
              : 0;
          const second =
            b.node.donationInfo && b.node.donationInfo.donationAmount
              ? b.node.donationInfo.donationAmount
              : 0;
          return second - first;
        },
        render: (text: string) => (text ? `$${text}` : ``),
      },
      {
        title: 'Donation Id',
        dataIndex: 'node.donationId',
        editable: false,
        key: 'donationId',
      },
      {
        title: 'Host Kit Quantity',
        dataIndex: 'node.hostKitQuantity',
        editable: false,
        key: 'hostKitQuantity',
        render: (text: string) => {
          const hkq = parseInt(text);
          return Number.isNaN(hkq) ? '' : hkq;
        },
      },
      {
        title: 'Host Kit Order Numbers',
        dataIndex: 'node.hostKitOrderNumbers',
        editable: false,
        width: '200px',
        key: 'hostKitOrderNumbers',
        render: (text: string) => {
          const orderNumbersArray = text ? text.split(/[ ,]+/) : [];
          return orderNumbersArray.map((num) => <div key={num}>{num}</div>);
        },
      },
      {
        title: 'Host Rewards Code',
        dataIndex: 'node.hostRewards',
        editable: false,
        key: 'hostRewards',
        render: (hostRewards: Array<any>) => {
          return hostRewards.map((reward) => (
            <Link to="/promos" key={reward.code}>
              {reward.code}
            </Link>
          ));
        },
      },
      {
        dataIndex: 'operation',
        key: 'operation',
        width: '125px',
        fixed: 'right',
        render: (text: string, record: any) => {
          const editable = this.isEditing(record);
          const { editingKey } = this.state;
          return (
            <div>
              <Mutation
                mutation={EventEditMutation}
                onError={this.onMutationError}
                onCompleted={this.onEventUpdated}
              >
                {(editEvent, { data }) => (
                  <div>
                    {editable ? (
                      <span>
                        <EditableContext.Consumer>
                          {(form) => (
                            <Popconfirm
                              title="Sure to save?"
                              cancelText="Close"
                              okText="Yes"
                              onConfirm={() =>
                                this.save(form, record, editEvent)
                              }
                            >
                              <Button
                                htmlType="button"
                                style={{ marginRight: 8 }}
                                type="primary"
                                shape="circle"
                                icon="save"
                                size="small"
                              />
                            </Popconfirm>
                          )}
                        </EditableContext.Consumer>
                        <React.Fragment>
                          <Button
                            onClick={this.cancel}
                            htmlType="button"
                            type="default"
                            shape="circle"
                            icon="close"
                            size="small"
                          />
                          {this.state.isUpdating && this.isEditing(record) && (
                            <Spin style={{ marginLeft: 8 }} size="small" />
                          )}
                        </React.Fragment>
                      </span>
                    ) : (
                      <span>
                        <Tooltip title="Edit">
                          <Button
                            disabled={editingKey !== ''}
                            onClick={() => this.edit(record)}
                            htmlType="button"
                            type="primary"
                            shape="circle"
                            icon="edit"
                            size="small"
                          />
                        </Tooltip>
                        {/*<Mutation*/}
                        {/*mutation={PaymentDeleteMutation}*/}
                        {/*onError={this.onMutationError}*/}
                        {/*onCompleted={this.onEventUpdated}>*/}
                        {/*{(deletePayment, { data }) =>*/}
                        {/*<Popconfirm*/}
                        {/*title="Delete Payment?"*/}
                        {/*cancelText="Close"*/}
                        {/*okText="Delete"*/}
                        {/*onConfirm={() =>*/}
                        {/*this.deletePayment(record, deletePayment)}>*/}
                        {/*<span>*/}
                        {/*<Button*/}
                        {/*htmlType="button"*/}
                        {/*style={{ marginLeft: 8 }}*/}
                        {/*type="danger"*/}
                        {/*shape="circle"*/}
                        {/*icon="delete"*/}
                        {/*size="default"*/}
                        {/*/>*/}
                        {/*{this.state.isCancelling &&*/}
                        {/*this.isCancelling(record) &&*/}
                        {/*<Spin*/}
                        {/*style={{ marginLeft: 8 }}*/}
                        {/*size="small"*/}
                        {/*/>}*/}
                        {/*</span>*/}
                        {/*</Popconfirm>}*/}
                        {/*</Mutation>*/}
                      </span>
                    )}
                  </div>
                )}
              </Mutation>
            </div>
          );
        },
      },
    ];
  }

  getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex.split('.')[1]}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) => {
      return record.node[dataIndex.split('.')[1]]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  handleSearch = (selectedKeys: Array<string>, confirm: () => void) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters: () => void) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  isEditing = (record: any) => {
    return record.node.id === this.state.editingKey;
  };

  isCancelling = (record: any) => {
    return record.node.id === this.state.cancellingKey;
  };

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  onEventUpdated = () => {
    return this.setState(
      {
        cancellingKey: '',
        editingKey: '',
        isUpdating: false,
        isCancelling: false,
      },
      () => this.props.resetEvents(),
    );
  };

  onMutationError = () => {
    return this.setState({
      isUpdating: false,
      editingKey: '',
      isCancelling: false,
      cancellingKey: '',
    });
  };

  save = (form: any, record: any, editEvent: (input: object) => void) => {
    if (!form || this.state.isUpdating) return null;
    form.validateFields((error: Object, row: any) => {
      if (error) {
        return;
      }
      const hostKitForm = {
        hostKitQuantity: row.node.hostKitQuantity.toString(),
        hostKitOrderNumbers: row.node.hostKitOrderNumbers.toString(),
      };
      const donationId = record.node.donationId;
      const eventId = record.node?.eventId;
      const { selectedUserId } = this.props;

      this.setState({ record, row, isUpdating: true });

      editEvent({
        variables: {
          input: {
            selectedUserId,
            hostKitForm,
            donationId,
            eventId,
          },
        },
      });
    });
  };

  deletePayment = (record: any, deletePayment: (input: object) => void) => {
    if (this.state.isUpdating || this.state.isCancelling) return null;
    this.setState({ isCancelling: true, cancellingKey: record.node.id });
    const { selectedUserId } = this.props;

    deletePayment({
      variables: {
        input: {
          selectedUserId,
          stripeTokenId: record.node.stripePayment.paymentId,
        },
      },
    });
  };

  edit = (key: any) => {
    if (this.state.isUpdating) return null;
    this.setState({ editingKey: key.node.id });
  };

  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          key: col.key,
          title: col.title,
          inputType: col.key === 'hostKitOrderNumbers' ? 'textArea' : 'text',
          dataIndex: col.dataIndex,
          editing: this.isEditing(record),
        }),
      };
    });

    const { events, form } = this.props;
    const scroll = { x: 3000 };
    return (
      <EditableContext.Provider value={form}>
        <Table
          bordered
          scroll={scroll}
          columns={columns}
          dataSource={events}
          components={components}
          rowKey={(row: any) => row.node.id}
          rowClassName="editable-row"
        />
      </EditableContext.Provider>
    );
  }
}
const EditableFormTable = Form.create()(EventsTable);
export default EditableFormTable;
