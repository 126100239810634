import React, { Component } from 'react';
import { Tabs } from 'antd';
import { navigate } from 'gatsby';

const TabPane = Tabs.TabPane;
interface TabsComponentProps {
  isCeoSelected: any;
  selectedUserId: string;
  selectedValue: string;
}

class TabsComponent extends Component<TabsComponentProps, {}> {
  onTabClick = (key: string, e: React.ChangeEvent<any>) => {
    e.preventDefault();
    const { selectedUserId } = this.props;
    navigate(`/${key}?user=${selectedUserId}`);
  };

  render() {
    const { isCeoSelected, selectedValue } = this.props;
    return (
      <div style={{ marginTop: 30, marginBottom: 6 }}>
        <Tabs
          type="card"
          activeKey={selectedValue}
          tabPosition="top"
          onTabClick={this.onTabClick}
        >
          <TabPane tab="Account Info" key="account-info" />
          {isCeoSelected && (
            <TabPane tab="Personal Orders" key="personal-orders" />
          )}
          {isCeoSelected && (
            <TabPane tab="Customer Orders" key="customer-orders" />
          )}
          {!isCeoSelected && <TabPane tab="Orders" key="orders" />}
          <TabPane tab="Addresses" key="addresses" />
          <TabPane tab="Payments" key="payments" />
          {isCeoSelected && <TabPane tab="Customers" key="customers" />}
          <TabPane tab="Subscriptions" key="subscriptions" />
          <TabPane tab="Events" key="events" />
          <TabPane tab="Donations" key="all-donations" />
        </Tabs>
      </div>
    );
  }
}

export default TabsComponent;
